import { MAX_IMAGE_WIDTH } from 'configuration'
import { Image } from 'image-js'

const compressImage = async (file: File) => {
  try {
    const buffer = await file.arrayBuffer()
    const image = await Image.load(buffer)
    const reducedImage = image.resize({
      width: MAX_IMAGE_WIDTH,
    })

    // NOTE make sure we are not returning bigger files
    if (image.size < reducedImage.size) return file

    // NOTE turn into blob to be able to specify the type (e.g. image/webp)
    const blob = await reducedImage.toBlob(file.type)

    return new File([blob], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    })
  } catch (error) {
    console.error('Error compressing image: ' + error)
    return file
  }
}

export default compressImage
