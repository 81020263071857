import { useEffect, useState, useCallback } from 'react'

export type ConnectionStatus = boolean

export const ONLINE: ConnectionStatus = true
export const OFFLINE: ConnectionStatus = false

export const Status = {
  ONLINE,
  OFFLINE,
}

const getOnlineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true

export default function useConnectionStatus(debug = false) {
  const [connectionStatus, setConnectionStatus] =
    useState<boolean>(getOnlineStatus())

  const updateStatus = useCallback(
    (status: boolean) => {
      if (debug) {
        console.log(
          `[ConnectionStatus] Status changed: ${status ? 'ONLINE' : 'OFFLINE'}`,
        )
      }
      setConnectionStatus(status)
    },
    [debug],
  )

  const onlineHandler = useCallback(
    () => updateStatus(Status.ONLINE),
    [updateStatus],
  )
  const offlineHandler = useCallback(
    () => updateStatus(Status.OFFLINE),
    [updateStatus],
  )

  useEffect(() => {
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)
    if (debug) {
      console.log(
        `[ConnectionStatus] Initial status: ${connectionStatus ? 'ONLINE' : 'OFFLINE'}`,
      )
    }

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [offlineHandler, onlineHandler, debug, connectionStatus])

  return connectionStatus
}
