import { useCallback, useContext, useState } from 'react'
import UserContext from 'context/UserContext'
import {
  loginWithCredentials,
  getUserDetails,
  refreshToken,
  isTokenExpired,
} from 'services/user'
import { UserCredentials } from '../core/User'
import useTranslation from './useTranslation'
import useConfig from './useConfig'
import { useNetwork } from 'context/NetworkContext'
import { log } from 'common/devLog'

interface IUseUser {
  username: string | null
  isLogged: boolean
  isLoginLoading: boolean
  loginError: string
  isUserLoaded: boolean
  login: (credentials: UserCredentials) => Promise<void>
  logout: () => void
  tryRefreshToken: (forceLogout?: boolean) => Promise<any>
  needReLogin: () => boolean
}

const retryWithBackoff = async (
  fn: () => Promise<any>,
  maxRetries = 3,
  delay = 1000,
) => {
  let retries = 0
  while (retries < maxRetries) {
    try {
      return await fn()
    } catch (err) {
      retries++
      log(`Retry ${retries} failed, retrying...`)
      if (retries >= maxRetries) {
        throw err
      }
      const currentRetries = retries
      await new Promise((resolve) =>
        setTimeout(resolve, delay * 2 ** currentRetries),
      ) // exponential backoff delay increase (1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024)
    }
  }
  return Promise.reject('Max retries reached')
}

export default function useUser(): IUseUser {
  const { jwt, isLoaded, username, saveUser, clearUser } =
    useContext(UserContext)
  const { isOffline } = useNetwork()
  const t = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { fetchAppConfig } = useConfig()

  const login = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      if (username === '' || password === '') {
        setError(t('login.requiredError'))
        return
      }

      setIsLoading(true)

      return await loginWithCredentials({
        username,
        password,
      })
        .then(async (jwt) => {
          // fetch user details
          await saveUser(username, jwt)
          await getUserDetails(jwt).then(async (userInfo) => {
            setError('')
            //console.log('userInfo', userInfo)
            await fetchAppConfig(jwt)
            setIsLoading(false)
          })
        })
        .catch((err) => {
          setIsLoading(false)
          setError(t('login.authError'))
          console.log('Login error', err)
        })
    },
    [t, saveUser, fetchAppConfig],
  )

  const logout = useCallback(() => {
    clearUser()
  }, [clearUser])

  const tryRefreshToken = useCallback(
    async (forceLogout = false) => {
      if (isOffline) {
        log('Offline, not refreshing token')
        return Promise.resolve()
      }
      return retryWithBackoff(refreshToken)
        .then(async (token) => {
          await saveUser(null, token)
          return token
        })
        .catch((err) => {
          log('Error refreshing token', err)
          if (isTokenExpired() && forceLogout) {
            logout()
            throw new Error('INVALID_TOKEN')
          }
        })
    },
    [logout, saveUser, isOffline],
  )

  return {
    username,
    isLogged: Boolean(jwt),
    isLoginLoading: isLoading,
    loginError: error,
    isUserLoaded: isLoaded,
    needReLogin: isTokenExpired,
    login,
    logout,
    tryRefreshToken,
  }
}
