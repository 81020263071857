import { Submission, SubmissionFile } from './Submission'
import generateId from 'common/generateId'
import {
  pipe,
  lensPath,
  last,
  update,
  over,
  Lens,
  view,
  assocPath,
  assoc,
  dissocPath,
  omit,
} from 'ramda'
import { LinkChild } from './LinkChildren'
import { ModelSummary } from './ModelSummary'

export function duplicateSubmission({
  original,
  newIdMapper = generateNewIds,
  getModelSummary = () => undefined,
}: {
  original: Submission
  newIdMapper?: (originalIds: string[]) => Map<string, string>
  getModelSummary?: (modelId: string) => ModelSummary | undefined
}): Submission {
  // create new id for every children keeping it in the replace map
  // newIdMapper is injected for easier testing
  const newIds = newIdMapper([original.id, ...Object.keys(original.children)])

  return pipe(
    processsParentLinkFields({ idMap: newIds, getModelSummary }),
    processAncestors(newIds),
    removeFiles,
    replaceIds(newIds),
  )(Object.assign({}, original))
}

function generateNewIds(originalIds: string[]): Map<string, string> {
  return new Map(originalIds.map((id) => [id, generateId()]))
}

const getFilteredData =
  (nonDuplicableFields = [] as string[]) =>
  (submission: Submission | LinkChild) => {
    return omit(nonDuplicableFields, submission.data)
  }

const tryRemoveChildren = (childId: string, submission: Submission) => {
  const childrenIdsToRemove = Object.values(submission.children)
    .filter((child) => child.ancestors.includes(childId))
    .map((child) => child.id)

  for (let id of childrenIdsToRemove) {
    delete submission.children[id]
  }
  delete submission.children[childId]
  return submission
}

const processsParentLinkFields = ({
  idMap,
  getModelSummary,
}: {
  idMap: Map<string, string>
  getModelSummary: (modelId: string) => ModelSummary | undefined
}) => {
  const getNonDuplicableFields = (modelId: string) => {
    const modelSummary = getModelSummary(modelId) ?? ({} as ModelSummary)
    return modelSummary?.nonDuplicateFields ?? []
  }
  const isLinkField = (fieldName: string, modelId: string) => {
    const modelSummary = getModelSummary(modelId)
    const linkFields = modelSummary?.linkFields ?? []
    return linkFields.includes(fieldName)
  }

  return (submission: Submission): Submission => {
    const rootNonDuplicableFields =
      getNonDuplicableFields(submission.modelId) ?? []
    let data = getFilteredData(rootNonDuplicableFields)(submission)
    let processed = Object.assign({}, submission, { data })
    for (let childId of Object.keys(submission.children)) {
      const childSubmission = processed.children[childId]
      if (!childSubmission) {
        continue
      }
      const newId = idMap.get(childId)

      const processedChildData = getFilteredData(
        getNonDuplicableFields(childSubmission.modelId),
      )(childSubmission)

      const isFirstLevelChild = childSubmission.ancestors.length === 1
      let linkValueLens: Lens<Submission, any>
      let linkParentLens: Lens<Submission, any>
      if (isFirstLevelChild) {
        if (rootNonDuplicableFields.includes(childSubmission.parentField)) {
          if (isLinkField(childSubmission.parentField, submission.modelId)) {
            processed = tryRemoveChildren(childId, processed)
          }
          continue
        }
        linkValueLens = lensPath(['data', childSubmission.parentField])
        linkParentLens = lensPath(['data'])
      } else {
        const parentId = last(childSubmission.ancestors) as string
        const parent = submission.children[parentId]
        const parentNonDuplicableFields = getNonDuplicableFields(parent.modelId)
        if (parentNonDuplicableFields.includes(childSubmission.parentField)) {
          if (isLinkField(childSubmission.parentField, parent.modelId)) {
            processed = tryRemoveChildren(childId, processed)
          }
          continue
        }

        linkValueLens = lensPath([
          'children',
          parentId,
          'data',
          childSubmission.parentField,
        ])
        linkParentLens = lensPath(['children', parentId, 'data'])
      }
      const childrenIds = view<Submission, string[]>(linkValueLens, processed)
      const hasParentField = childrenIds !== undefined
      if (hasParentField) {
        const originalIdIndex = childrenIds.indexOf(childId)
        processed = over(
          linkValueLens,
          update(originalIdIndex, newId),
          processed,
        )
      } else {
        processed = over(
          linkParentLens,
          omit([childSubmission.parentField]),
          processed,
        )
      }
      //update child data
      processed = assocPath(
        ['children', childId, 'data'],
        processedChildData,
        processed,
      )
    }
    return processed
  }
}

const processAncestors =
  (idMap: Map<string, string>) =>
  (submission: Submission): Submission => {
    return Object.keys(submission.children).reduce(
      (s: Submission, childId: string) => {
        const modifiedAncestors = s.children[childId].ancestors.map(
          (ancestorId) => idMap.get(ancestorId) as string,
        )
        return assocPath(
          ['children', childId, 'ancestors'],
          modifiedAncestors,
          s,
        )
      },
      { ...submission },
    )
  }

const replaceIds =
  (idMap: Map<string, string>) =>
  (submission: Submission): Submission => {
    submission.id = idMap.get(submission.id) as string
    for (let childId of Object.keys(submission.children)) {
      const newId = idMap.get(childId)!
      submission.children[newId] = Object.assign(submission.children[childId], {
        id: idMap.get(childId),
      })
      delete submission.children[childId]
    }
    return submission
  }
//check remove files for remote submission
function removeFiles(submission: Submission): Submission {
  const files = [...submission.files]
  return files.reduce(
    (s: Submission, f: SubmissionFile) => {
      return dissocPath<Submission>(f.dataPath, s)
    },
    assoc('files', [], submission),
  )
}
