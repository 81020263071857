import React from 'react'
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
} from '@ionic/react'
import SelectOption from 'ui/components/fields/SelectOption'
import useTranslation from 'hooks/useTranslation'

export interface LanguageSelectorProps {
  options: Array<{
    id: string
    value: string
    label: string
  }>
  value: string
  isOpen: boolean
  onDidDismiss?: () => void
  onCancel?: () => void
  onAccept?: () => void
  onSelect: (value: string) => void
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  options,
  value,
  isOpen,
  onDidDismiss,
  onCancel,
  onAccept,
  onSelect,
}) => {
  const t = useTranslation()
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('settings.chooseLanguage')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {options.map((option) => (
            <SelectOption
              key={option.id}
              value={option.value}
              label={option.label}
              onSelect={() => onSelect(option.value)}
              selected={value === option.value}
            />
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}

export default LanguageSelector
