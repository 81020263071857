import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FieldProps } from './common'
import FormField from '../forms/FormField'
import './Sketch.css'
import useFiles, { FileValue, downloadImage } from 'hooks/useFiles'
import useToast from 'hooks/useToast'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import {
  cameraOutline,
  imagesOutline,
  trashOutline,
  saveOutline,
  chevronBack,
} from 'ionicons/icons'
import offlinePlaceholder from 'logos/offline.png'
import useTranslation from 'hooks/useTranslation'
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas'
import compressImage from 'common/compressImage'
import { readFile } from 'services/files'
import FullImagePreview from './FullImagePreview'
import useLocalConfiguration from 'hooks/useLocalConfiguration'
import FileActionButtons from './FileActions'
import { fetchRemoteFile } from 'services/network/api'
import { EditButtons, SketchButtons } from './SketchButtons'
import useForm from 'hooks/useForm'
import { getCurrentFieldValue } from 'context/FormContext/selectors'
import { prefixRemoteUri } from 'services/files'
import useUser from 'hooks/useUser'
import { useNetwork } from 'context/NetworkContext'
import { log } from 'common/devLog'
import OfflineState from 'ui/layouts/headers/OfflineState'

interface SketchProps {
  height?: number
  width?: number
  defaultColor?: string
  defaultStrokeWidth?: number
  aspectRatio?:
    | 'none'
    | 'xMidYMid'
    | 'xMidYMax'
    | 'xMaxYMax'
    | 'xMinYMax'
    | 'xMinYMid'
    | 'xMaxYMid'
    | 'xMaxYMin'
    | 'xMidYMin'
    | 'xMinYMin'
  showGrid: boolean
  backgroundColor: string
  exportWithBackground: boolean
  backgroundFileId?: string
  /** Which field on the same model represent its background */
  backgroundFieldName?: string
  allowCustomBackground: boolean
}

interface SketchFieldProps extends SketchProps, FieldProps {
  value?: FileValue
}

interface SketchFullScreenProps extends SketchProps {
  isOpen: boolean
  onCancel: () => void
  onSave: (data: string) => void
  onClear: () => void
  title: string
}

const ALLOWED_IMAGE_EXTENSIONS = 'image/*;capture=camera'

const makeSketchFileName = (name: string) => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const formattedDate = `${year}${month}${day}`
  return `${name}_${formattedDate}.png`
}

const isRemoteFile = (value: any): boolean => {
  return Boolean(value && value.uri)
}

const Sketch: React.FC<SketchFieldProps> = (props) => {
  const {
    readOnly,
    name,
    label,
    hasError,
    hint,
    fileId,
    tabIndex,
    isRequired,
    errorText,
    value,
  } = props
  const t = useTranslation()
  const Toast = useToast()
  const { isOffline } = useNetwork()
  const { tryRefreshToken, needReLogin } = useUser()
  const { handleImageChange, imageValue } = useFiles(name, value)
  const [remoteImageState, setRemoteImageState] = useState({
    isFetching: false,
    error: false,
  })
  const cancelController = useRef(new AbortController())

  const { config } = useLocalConfiguration()

  const [imageDataURL, saveImageDataURL] = useState<string>()
  const [actionsVisible, setActionsVisible] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [canvasVisible, setCanvasVisible] = useState(false)

  const handleDeleteFile = useCallback(() => {
    handleImageChange(name, undefined)
    saveImageDataURL(undefined)
    setCanvasVisible(false)
  }, [handleImageChange, name])

  const handleUpload = useCallback(
    async (data?: string) => {
      if (data) {
        const byteString = atob(data.split(',')[1])
        const mimeString = data.split(',')[0].split(':')[1].split(';')[0]
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        const blob = new Blob([ab], { type: mimeString })
        const fileName = makeSketchFileName(name)
        const file = new File([blob], fileName, { type: 'image/png' })
        const reducedFile = await compressImage(file)
        handleImageChange(name, reducedFile)
        saveImageDataURL(data)
        Boolean(config.autoDownloadImages) && downloadImage(data, reducedFile)
        setCanvasVisible(false)
      }
    },
    [handleImageChange, name, config.autoDownloadImages],
  )
  // load image as ObjectURL
  useEffect(() => {
    if (!imageValue) return

    const loadImage = async () => {
      try {
        if (imageValue instanceof File) {
          const url = URL.createObjectURL(imageValue)
          saveImageDataURL(url)
        } else {
          if (imageValue.uri) {
            log('Got remote file uri', imageValue.uri)
            if (isOffline) {
              Toast.warning(t('notifications.offlineLoadImage'), 3000)
              setRemoteImageState({
                isFetching: false,
                error: true,
              })
              return
            }
            if (needReLogin()) {
              await tryRefreshToken().catch((error) => console.error(error))
            }
            setRemoteImageState({
              isFetching: true,
              error: false,
            })
            fetchRemoteFile(imageValue.uri, cancelController.current.signal)
              .then((x: any) => URL.createObjectURL(x))
              .then((url) => {
                saveImageDataURL(url)
                setRemoteImageState({
                  isFetching: false,
                  error: false,
                })
              })
              .catch((err: Error) => {
                if (err.name !== 'AbortError') {
                  setRemoteImageState({
                    isFetching: false,
                    error: true,
                  })
                  Toast.warning(t('notifications.failedLoadImage'), 3000)
                }
              })
          }
        }
      } catch (error) {
        console.error('[sketch] Error loading image', error)
      }
    }
    loadImage()
    // remote files
  }, [imageValue, Toast, t, needReLogin, tryRefreshToken, isOffline])

  // revoke (clean memory)
  useEffect(() => {
    let controller = cancelController.current
    return () => {
      if (imageDataURL) {
        URL.revokeObjectURL(imageDataURL)
      }
      if (controller) {
        controller.abort()
      }
    }
  }, [imageDataURL])

  const handleDownload = useCallback(() => {
    if (imageDataURL != null && imageValue != null) {
      downloadImage(imageDataURL, imageValue)
    }
  }, [imageDataURL, imageValue])

  return (
    <FormField
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      fileId={fileId}
      tabIndex={tabIndex}
      readOnly={readOnly}
    >
      <OfflineState
        visible={isRemoteFile(value)}
        message={t('notifications.offlineLoadImage')}
      />
      <div className="sketch-uploader-trigger">
        {imageDataURL ? (
          <p
            className="image-uploader-picture"
            onClick={() => setActionsVisible(true)}
          >
            <IonImg
              src={imageDataURL}
              style={{ maxHeight: '300px' }}
              onScroll={console.log}
            />
          </p>
        ) : (
          <IonButton
            className="sketch-uploader-button"
            fill="clear"
            onClick={() => setCanvasVisible(true)}
            disabled={readOnly}
          >
            <IonText className="sketch-uploader-placeholder">
              {!remoteImageState.error && (
                <IonIcon
                  icon={readOnly ? imagesOutline : cameraOutline}
                  size="large"
                  className="ion-margin-vertical"
                />
              )}
              {remoteImageState.error ? (
                <IonImg src={offlinePlaceholder} />
              ) : (
                <IonText className="sketch-uploader-placeholder-text">
                  {remoteImageState.isFetching ? (
                    <IonSpinner />
                  ) : readOnly ? (
                    t('withoutValue')
                  ) : (
                    t('buttons.takeSketch')
                  )}
                </IonText>
              )}
            </IonText>
          </IonButton>
        )}
      </div>
      <FullScreenSketch
        isOpen={canvasVisible}
        onCancel={() => setCanvasVisible(false)}
        onSave={(dataURL) => handleUpload(dataURL)}
        onClear={handleDeleteFile}
        title={label}
        height={props.height}
        width={props.width}
        backgroundColor={props.backgroundColor}
        backgroundFileId={props.backgroundFileId}
        backgroundFieldName={props.backgroundFieldName}
        allowCustomBackground={props.allowCustomBackground}
        exportWithBackground={props.exportWithBackground}
        defaultColor={props.defaultColor}
        defaultStrokeWidth={props.defaultStrokeWidth}
        aspectRatio={props.aspectRatio}
        showGrid={props.showGrid}
      />
      <FileActionButtons
        open={actionsVisible}
        value={imageValue}
        readOnly={readOnly}
        onDownload={handleDownload}
        onDelete={handleDeleteFile}
        onModifyClick={() => setCanvasVisible(true)}
        onPreviewClick={() => setPreviewVisible(true)}
        onDismiss={() => setActionsVisible(false)}
      />
      {imageValue && (
        <FullImagePreview
          isOpen={previewVisible}
          title={imageValue.name}
          onClose={() => setPreviewVisible(false)}
          src={imageDataURL}
        />
      )}
    </FormField>
  )
}

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
}

const loadBackground = async (
  value: string | FileValue | undefined,
  setImage: (f: string | ArrayBuffer | null) => void,
) => {
  if (!value) return
  const loadImage = async (file: Blob) => {
    const reader = new FileReader()
    reader.onload = () => setImage(reader.result)
    reader.readAsDataURL(file)
  }
  try {
    if (typeof value === 'string') {
      //backgroundId
      const file = await readFile(value).catch((error) => console.error(error))
      if (file) await loadImage(file.blob)
    } else if (value instanceof File) {
      // current field background is a file
      await loadImage(value)
    } else if (value.uri) {
      // current field background is a remote file
      const blob = await fetchRemoteFile(value.uri).catch((error) =>
        console.error(error),
      )
      if (blob) await loadImage(blob)
    }
  } catch (error) {
    console.error(error)
  }
}

const FullScreenSketch: React.FC<SketchFullScreenProps> = (props) => {
  const {
    isOpen,
    onCancel,
    onSave,
    onClear,
    title,
    width = 500,
    height = 500,
    defaultColor = '#000000',
    defaultStrokeWidth = 4,
    backgroundColor = '#ffffff',
    showGrid: allowGrid = false,
    aspectRatio = 'none',
    allowCustomBackground,
    exportWithBackground = false,
    backgroundFieldName,
    backgroundFileId,
  } = props
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { state, getFile } = useForm()

  const [backgroundImage, setBackgroundImage] = useState<any>(undefined)
  const [isLandscape, setIsLandscape] = useState<boolean>(
    window.matchMedia('(orientation: landscape)').matches || true,
  )
  const getBackgroundFieldImage = useCallback(() => {
    if (!backgroundFieldName) return
    const fieldValue = getCurrentFieldValue(backgroundFieldName, state) as any
    if (!fieldValue) return
    if (typeof fieldValue === 'string') {
      return getFile(fieldValue)?.file ?? fieldValue
    }
    return fieldValue.uri
      ? {
          ...fieldValue,
          uri: prefixRemoteUri(fieldValue.uri),
        }
      : fieldValue
  }, [backgroundFieldName, state, getFile])

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia('(orientation: landscape)').matches)
    }
    window.addEventListener('orientationchange', handleOrientationChange)
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])
  useEffect(() => {
    const loadBackgroundImage = async () => {
      if (backgroundFileId || backgroundFieldName) {
        await loadBackground(
          getBackgroundFieldImage() ?? backgroundFileId,
          setBackgroundImage,
        )
      }
    }
    loadBackgroundImage()
  }, [backgroundFileId, getBackgroundFieldImage, backgroundFieldName])

  const [sketchState, setSketchState] = useState({
    strokeColor: defaultColor,
    strokeWidth: defaultStrokeWidth,
    eraseMode: false,
    showGrid: allowGrid,
  })
  const canvasRef = useRef<ReactSketchCanvasRef | null>(null)
  const handleUndoClick = () => {
    canvasRef.current?.undo()
  }
  const handleRedoClick = () => {
    canvasRef.current?.redo()
  }
  const handleClearClick = async () => {
    //reload the background image
    if (backgroundFileId)
      await loadBackground(
        getBackgroundFieldImage() ?? backgroundFileId,
        setBackgroundImage,
      )

    canvasRef.current?.clearCanvas()
  }
  const handleColorChange = (color: string) => {
    setSketchState((s) => ({ ...s, strokeColor: color }))
  }
  const handleStrokeChange = (strokeWidth: number) => {
    setSketchState((s) => ({ ...s, strokeWidth }))
  }
  const handleModeChange = (mode: 'draw' | 'erase') => {
    const value = mode === 'erase'
    setSketchState((s) => {
      canvasRef.current?.eraseMode(value)
      return { ...s, eraseMode: value }
    })
  }
  const handleFileInputClick = useCallback(
    () => inputFileRef.current?.click(),
    [inputFileRef],
  )

  const handleUploadBackground = async (e: any) => {
    const file = e.target.files[0]
    if (file) {
      const reducedFile = await compressImage(file)
      const reader = new FileReader()
      reader.onload = () => {
        setBackgroundImage(reader.result)
      }
      reader.readAsDataURL(reducedFile)
    }
  }
  const handleSave = async () => {
    const dataURL = await canvasRef.current?.exportImage('png')
    if (dataURL) {
      onSave(dataURL)
    }
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onCancel}>
      <IonHeader
        className={`sketch-header ${isLandscape ? 'landscape' : 'portrait'}`}
      >
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end" className="sketch-header-buttons">
            <IonButton
              expand="block"
              fill="clear"
              color="danger"
              className="ion-margin-end"
              onClick={onClear}
            >
              <IonIcon icon={trashOutline} slot="icon-only" />
            </IonButton>
            <IonButton className="ion-margin-start" onClick={onCancel}>
              <IonIcon icon={chevronBack} slot="icon-only" />
            </IonButton>
            <IonButton
              className="ion-margin-start"
              expand="block"
              color="primary"
              fill="clear"
              onClick={handleSave}
            >
              <IonIcon icon={saveOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={` ${
          isLandscape ? 'sketch-content-landscape' : 'sketch-content-portrait'
        }`}
      >
        <div
          className={`sketch-canvas-container ${
            isLandscape ? 'landscape' : 'portrait'
          }`}
        >
          <SketchButtons
            isLandscape={isLandscape}
            onClear={handleClearClick}
            onUndo={handleUndoClick}
            onRedo={handleRedoClick}
            onLoadBackground={handleFileInputClick}
            allowCustomBackground={allowCustomBackground}
            hasBackground={Boolean(backgroundImage)}
            onRemoveBackground={async () => {
              setBackgroundImage(undefined)
            }}
            allowGrid={allowGrid}
            gridState={sketchState.showGrid}
            onChangeShowGrid={() =>
              setSketchState((s) => ({ ...s, showGrid: !s.showGrid }))
            }
          />
          <div
            className="sketch-canvas"
            style={{
              height: `${height + 10}px`,
              width: `${width + 10}px`,
              margin: 'auto',
            }}
          >
            <div
              className={
                sketchState.showGrid ? 'sketch-grid' : 'sketch-grid hidden'
              }
            >
              <ReactSketchCanvas
                width={`${width}px`}
                height={`${height}px`}
                ref={canvasRef}
                style={styles}
                strokeColor={sketchState.strokeColor}
                strokeWidth={sketchState.strokeWidth}
                eraserWidth={sketchState.strokeWidth}
                canvasColor={backgroundColor}
                backgroundImage={backgroundImage}
                preserveBackgroundImageAspectRatio={aspectRatio}
                exportWithBackgroundImage={exportWithBackground}
              />
            </div>
          </div>
          <EditButtons
            isLandscape={isLandscape}
            onColorChange={handleColorChange}
            onStrokeChange={handleStrokeChange}
            onModeChange={handleModeChange}
            color={sketchState.strokeColor}
            strokeWidth={sketchState.strokeWidth}
            eraseMode={sketchState.eraseMode}
          />
        </div>
        <input
          type="file"
          accept={ALLOWED_IMAGE_EXTENSIONS}
          className="hidden-input-file"
          ref={inputFileRef}
          onChange={handleUploadBackground}
          hidden
        />
      </IonContent>
      <IonFooter
        className={`sketch-footer ${isLandscape ? 'landscape' : 'portrait'}`}
      >
        <IonToolbar className="sketch-footer-toolbar">
          <IonButtons slot="start" /* className="sketch-footer-buttons" */>
            <IonButton
              expand="block"
              fill="clear"
              color="danger"
              className="ion-margin-end"
              onClick={onClear}
            >
              <IonIcon icon={trashOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" /* className="sketch-footer-buttons" */>
            <IonButton className="ion-margin-start" onClick={onCancel}>
              <IonIcon icon={chevronBack} slot="icon-only" />
            </IonButton>
            <IonButton
              className="ion-margin-start"
              expand="block"
              color="primary"
              fill="clear"
              onClick={handleSave}
            >
              <IonIcon icon={saveOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default Sketch
