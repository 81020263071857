import React from 'react'
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
} from '@ionic/react'
import {
  cloudUploadOutline,
  trashOutline,
  archiveOutline,
} from 'ionicons/icons'
import { useNetwork } from 'context/NetworkContext'
import useUser from 'hooks/useUser'

export interface ExportFooterProps {
  hasAddLinkMenu?: boolean
  hasNavigation?: boolean
  hasSaveMenu?: boolean
  hasDeleteAction?: boolean
  hasSendAction?: boolean
  hasDownloadAction?: boolean
  isDownloading: boolean
  onShowDeleteAlert?: () => void
  onExport?: () => void
  onDownload?: () => void
}

const ExportFooter: React.FC<ExportFooterProps> = ({
  hasDeleteAction,
  hasSendAction,
  hasDownloadAction,
  isDownloading,
  onShowDeleteAlert,
  onExport,
  onDownload,
}) => {
  const { isOffline } = useNetwork()
  const { needReLogin } = useUser()
  const disableExport = isOffline || needReLogin()

  return (
    <>
      <IonFooter className="app-footer">
        <IonToolbar>
          <IonButtons slot="start">
            {hasDeleteAction ? (
              <IonButton
                expand="block"
                fill="clear"
                color="danger"
                className="ion-margin-end"
                onClick={onShowDeleteAlert}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            ) : null}
          </IonButtons>
          <IonButtons slot="end">
            {hasDownloadAction ? (
              <IonButton expand="block" fill="clear" onClick={onDownload}>
                {isDownloading ? (
                  <IonSpinner name="dots" />
                ) : (
                  <IonIcon slot="icon-only" icon={archiveOutline} />
                )}
              </IonButton>
            ) : null}
          </IonButtons>
          <IonButtons slot="end">
            {hasSendAction ? (
              <IonButton
                expand="block"
                fill="clear"
                onClick={onExport}
                disabled={disableExport}
              >
                <IonIcon slot="icon-only" icon={cloudUploadOutline} />
              </IonButton>
            ) : null}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ExportFooter
