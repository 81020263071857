import React, { useCallback } from 'react'
import { IonInput } from '@ionic/react'
import FormField from '../forms/FormField'
import { FieldProps } from './common'
import { last } from 'ramda'
import { NumericField } from 'core/ContentField'

export interface NumericInputProps extends FieldProps {
  value?: number
  numericType?: NumericField['numericType']
  min?: Number
  max?: Number
  suffix?: NumericField['suffix']
  increment?: NumericField['increment']
  round?: NumericField['round']
}

const NumericInput: React.FC<NumericInputProps> = ({
  readOnly,
  label,
  name,
  hint,
  errorText,
  hasError,
  isRequired,
  onChange,
  value,
  numericType = 'integer',
  min,
  max,
  increment = numericType === 'decimal' ? '0.01' : undefined,
  tabIndex,
  fileId,
}) => {
  const handleChange = useCallback(
    (e: any) => {
      if (e.detail.value == null) return
      if (e.detail.value === '') {
        return onChange(name, undefined)
      }
      let lastCharacter = last<string>(e.detail.value)
      let numericValue = [',', '.'].includes(lastCharacter)
        ? e.detail.value
        : Number(e.detail.value)
      onChange(name, numericValue)
    },
    [onChange, name],
  )

  return (
    <FormField
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      fileId={fileId}
      readOnly={readOnly}
    >
      <IonInput
        readonly={readOnly}
        type="number"
        step={increment?.toString()}
        inputmode={numericType === 'decimal' ? 'decimal' : 'numeric'}
        name={name}
        onIonChange={handleChange}
        value={value}
        autocorrect="off"
        tabIndex={tabIndex}
        min={min?.toString()}
        max={max?.toString()}
      />
    </FormField>
  )
}

export default React.memo(NumericInput)
