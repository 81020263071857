import { get, post } from './network/api'
import { UserCredentials } from '../core/User'
import { API_URL } from './network/constants'
import { log } from 'common/devLog'

interface LocalUserInfo {
  username: string
  jwt: string
  expiresAt?: Date
}

const decodeJWT = (token: string): { exp: number } | null => {
  try {
    const [, payload] = token.split('.')
    const decoded = atob(payload)
    log('Decoded JWT successfully', { decoded })
    return JSON.parse(decoded)
  } catch (err) {
    log('Error decoding JWT')
    return null
  }
}

export const getTokenExpiration = (token: string) => {
  const decoded = decodeJWT(token)
  return decoded?.exp ? new Date(decoded.exp * 1000) : null
}

export const loginWithCredentials = async ({
  username,
  password,
}: UserCredentials) => {
  return await post(`${API_URL}/authentication`, {
    strategy: 'local',
    login: username,
    password,
  }).then(({ accessToken }) => accessToken)
}

export const refreshToken = async () => {
  const token = getJWT()
  return post(`${API_URL}/authentication`, {
    strategy: 'jwt',
    accessToken: token,
  }).then(({ accessToken }) => accessToken)
}

export async function getUserDetails(token: string) {
  return await get(`${API_URL}/me`, {
    Authorization: token,
  })
}

export const removeUserFromDB = async () => {
  localStorage.removeItem('user')
}

export const saveUserOnDB = async (username: string, jwt: string) => {
  const expiresAt = getTokenExpiration(jwt)
  try {
    localStorage.setItem('user', JSON.stringify({ username, jwt, expiresAt }))
  } catch (err) {
    console.error('Error setting user on localStorage')
    throw err
  }
}

export const getLoggedUser = (): LocalUserInfo | undefined => {
  const user = localStorage.getItem('user')
  try {
    if (user) {
      return JSON.parse(user)
    }
  } catch (err) {
    console.error('Error retrieving logged user from localStorage')
    throw err
  }
}

export const getJWT = () => {
  const user = getLoggedUser()
  return user?.jwt
}
//less than 1 hour
export const isTokenExpired = (time = 3600000) => {
  try {
    const user = getLoggedUser()
    log(`Checking token expiration for user`, {
      userName: user?.username,
      expiresAt: user?.expiresAt,
    })
    if (user?.expiresAt) {
      return Date.now() > new Date(user.expiresAt).getTime() - time
    }
    return true
  } catch (err) {
    log('Error checking token expiration', { error: err })
    return true
  }
}
