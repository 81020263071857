import isDev from 'common/isDev'
import useConnectionStatus from 'hooks/useConnectionStatus'
import { createContext, ReactNode, useContext, useState } from 'react'

interface NetworkContextProps {
  isOffline: boolean // combined status of navigator.onLine and manual offline mode
  isManualOffline: boolean // only manual offline mode managed by user interaction
  isRealOffline: boolean // only navigator.onLine status
  toggleOfflineMode: () => void
}

const NetworkContext = createContext<NetworkContextProps>({
  isOffline: false,
  isManualOffline: false,
  isRealOffline: false,
  toggleOfflineMode: () => {},
})

export const NetworkProvider = ({ children }: { children: ReactNode }) => {
  const isRealOffline = !useConnectionStatus(isDev)
  const [isManualOffline, setIsManualOffline] = useState(
    localStorage.getItem('isManualOffline') === 'true',
  )

  const isOffline = isRealOffline || isManualOffline

  const toggleOfflineMode = () => {
    setIsManualOffline((prev) => {
      const newState = !prev
      localStorage.setItem('isManualOffline', String(newState))
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'SET_OFFLINE_MODE',
          payload: newState,
        })
      }
      return newState
    })
  }

  return (
    <NetworkContext.Provider
      value={{
        isOffline,
        isManualOffline,
        isRealOffline,
        toggleOfflineMode,
      }}
    >
      {children}
    </NetworkContext.Provider>
  )
}

export const useNetwork = () => useContext(NetworkContext)
