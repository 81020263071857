import React, { useMemo, useCallback } from 'react'
import { IonActionSheet } from '@ionic/react'
import { eyeOutline, duplicateOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'

import useTranslation from 'hooks/useTranslation'
import useModel from 'hooks/useModel'

import { saveSubmission } from 'services/submissions'

import { Inspection } from 'core/Inspections'
import { createInspectionCopy } from 'core/mappers'
import { useModelSummaryContext } from 'context/ModelSummaryContext'

interface RemoteInspectionMenuProps {
  inspection: Inspection
  onDidDismiss: () => void
}

const RemoteInspectionMenu: React.FC<RemoteInspectionMenuProps> = (props) => {
  const { inspection, onDidDismiss } = props

  const t = useTranslation()
  const { getModelSummary } = useModelSummaryContext()

  const { push } = useHistory()

  const { data: model, isLoading: isLoadingModel } = useModel(
    inspection.inspectionModelId,
  )

  const handleViewInspection = useCallback(() => {
    // REVIEW stop using location state for intial data and the read only flag
    push(`/inspection/${inspection.assetId}/${inspection.inspectionModelId}`, {
      initialData: createInspectionCopy(inspection),
      readOnly: true,
    })
  }, [push, inspection])

  const handleDuplicateInspection = useCallback(async () => {
    const copy = createInspectionCopy(inspection, getModelSummary)
    await saveSubmission(copy)
    // NOTE submissions should be refetched after creating a copy of the
    // inspection, which could be achieved through the `refetch` function of
    // react-query, or by calling `queryCache.invalidateQueries`
    // NOTE however, we are doing this operation in the parent component for now
  }, [inspection, getModelSummary])

  const buttons = useMemo(() => {
    const buttons = [
      {
        text: t('inspectionMenu.view'),
        icon: eyeOutline,
        handler: handleViewInspection,
      },
    ]

    if (model?.ability?.canDuplicate) {
      buttons.push({
        text: t('inspectionMenu.duplicate'),
        icon: duplicateOutline,
        handler: handleDuplicateInspection,
      })
    }

    return buttons
  }, [t, model, handleViewInspection, handleDuplicateInspection])

  return (
    <IonActionSheet
      isOpen={!isLoadingModel}
      onDidDismiss={onDidDismiss}
      buttons={buttons}
    />
  )
}

export default RemoteInspectionMenu
