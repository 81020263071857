import React from 'react'
import { IonToast, setupIonicReact, IonApp } from '@ionic/react'

import AppRoutes from './AppRoutes'
import { useLocation } from 'react-router'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import { ReactQueryDevtools } from 'react-query-devtools'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

// Contexts
import { UserContextProvider } from './context/UserContext'
import { ToastContextProvider } from './context/ToastContext'
import { NetworkProvider } from 'context/NetworkContext'
import DbErrorBoundary from 'components/DBErrorBoundary'
import { IntlProviderWrapper } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import { IonReactRouter } from '@ionic/react-router'

import InstallAppToast from './InstallAppToast'

setupIonicReact()
export interface PWAProps {
  updateClick?: () => void
  isUpdateAvailable?: boolean
}

const UpdateToast: React.FC<PWAProps> = ({
  updateClick,
  isUpdateAvailable,
}) => {
  const t = useTranslation()
  const location = useLocation()
  return (
    <IonToast
      isOpen={isUpdateAvailable && location.pathname === '/'}
      message={t('notifications.updateVersionText')}
      position="bottom"
      buttons={[
        {
          side: 'end',
          text: t('notifications.updateVersionButton'),
          handler: updateClick,
        },
      ]}
    />
  )
}

const App: React.FC<PWAProps> = ({ updateClick, isUpdateAvailable }) => {
  return (
    <IonReactRouter>
      <IntlProviderWrapper>
        <DbErrorBoundary>
          <NetworkProvider>
            <UserContextProvider>
              <IonApp>
                <ToastContextProvider
                  value={{ color: 'primary', duration: 2000 }}
                >
                  <UpdateToast
                    updateClick={updateClick}
                    isUpdateAvailable={isUpdateAvailable}
                  />
                  <InstallAppToast />
                  <AppRoutes />
                </ToastContextProvider>
              </IonApp>
            </UserContextProvider>
            {/* {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-left"
        />
      )} */}
          </NetworkProvider>
        </DbErrorBoundary>
      </IntlProviderWrapper>
    </IonReactRouter>
  )
}

export default App
