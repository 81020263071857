import generateId from 'common/generateId'
import { ActionTypes, FormAction } from './actions'
import {
  last,
  update,
  append,
  lensPath,
  set,
  over,
  pipe,
  reject,
  filter,
  values,
  omit,
  isNil,
  map,
  prop,
  assocPath,
  dissocPath,
  lensProp,
  without,
  uniq,
  indexBy,
  mergeLeft,
  view,
  slice,
  findIndex,
} from 'ramda'
import { IHistory } from './models'
import { GeoLocation } from 'core/Geolocation'
import {
  SubmissionFile,
  createSubmissionFile,
  SubmissionType,
  SubmissionSourceType,
} from 'core/Submission'

export type SubmissionData = { [fieldName: string]: any }

export type ChildSubmission = {
  id: string
  modelId: string
  ancestors: string[]
  parentField: string
  sourceId?: string
  sourceModelId?: string
  data: SubmissionData
  meanings: { [title: string]: any }
}

export type ChildSubmissions = {
  [id: string]: ChildSubmission
}

export type FormState = {
  /**
   * Root submission id
   */
  rootId?: string
  /**
   * Root submission model id
   */
  rootModelId: string
  type?: SubmissionType
  sourceId?: string
  sourceModelId?: string
  sourceType?: SubmissionSourceType
  inventoryId?: string
  /**
   * Internal history like to navigate between children and submission sections
   * like links and groups
   */
  history: Array<IHistory>
  /**
   * Submission root data
   */
  rootData: SubmissionData
  /**
   * Submission children
   */
  formChildren: { [id: string]: ChildSubmission }
  /**
   * Currently selected group name
   */
  //groupName?: string
  /** Currently zoomed in link field name */
  //selectedLink?: string
  /**
   * GeoLocation information
   */
  location?: GeoLocation
  /**
   * Files attached to this submission
   */
  files: SubmissionFile[]
  newLocation?: GeoLocation
  confirmLocation: boolean
  /**
   * `true` if the submission has been modified in any way
   */
  isDirty: boolean
  lastError?: string
}

export const INITIAL_STATE: FormState = {
  history: [],
  rootData: {},
  rootModelId: '',
  formChildren: {},
  files: [],
  confirmLocation: false,
  isDirty: false,
}

const filesLens = lensProp<FormState, 'files'>('files')

function makeLinkFieldLens(ancestors: string[], linkName: string) {
  return lensPath(
    ancestors.length <= 1
      ? ['rootData', linkName]
      : ['formChildren', last(ancestors) as string, 'data', linkName],
  )
}

function makeAddToParent(
  ancestors: string[],
  parentField: string,
  newChildId: string,
) {
  const linkFieldLens = makeLinkFieldLens(ancestors, parentField)
  return over(linkFieldLens, append(newChildId))
}

const markAsDirty: (state: FormState) => FormState = assocPath(
  ['isDirty'],
  true,
)

const markAsUntouched: (state: FormState) => FormState = assocPath(
  ['isDirty'],
  false,
)

const isSameLocation = (a: GeoLocation, b: GeoLocation): Boolean => {
  return (
    a.altitude === b.altitude &&
    a.latitude === b.latitude &&
    a.longitude === b.longitude
  )
}

const sliceIfExist = (elements: any[]) => (index: number) =>
  slice(0, index !== -1 ? index : Infinity, elements)

function updateHistory(newChildHistory: IHistory, history: IHistory[]) {
  const { childId } = newChildHistory
  return pipe(
    findIndex((h: IHistory) => h.childId != null && h.childId === childId),
    sliceIfExist(history),
    append(newChildHistory),
  )(history)
}

export default function reducer(
  state: FormState,
  action: FormAction,
): FormState {
  process.env.NODE_ENV === 'development' &&
    console.debug('Form reducer dispatch', action)
  const lastHistory = last(state.history) as IHistory

  switch (action.type) {
    case ActionTypes.RESET:
      return INITIAL_STATE
    case ActionTypes.ADD_CHILD:
      const id = action.payload.id || generateId()
      const parentId = lastHistory.childId
      const newChild: ChildSubmission = {
        id,
        modelId: action.payload.linkModelId,
        parentField: action.payload.linkName,
        ancestors: isNil(parentId)
          ? [state.rootId as string]
          : uniq([...lastHistory.ancestors, parentId as string]),
        data: {},
        meanings: {},
      }

      const linkFieldLens = lensPath(
        parentId
          ? ['formChildren', parentId, 'data', action.payload.linkName]
          : ['rootData', action.payload.linkName],
      )
      const linkFieldData = view(linkFieldLens, state)
      let indexInParent = null
      if (Array.isArray(linkFieldData) && linkFieldData.length) {
        indexInParent = (linkFieldData.length + 1) as number
      }

      const newAddedChildHistory = {
        ...lastHistory,
        modelId: action.payload.linkModelId,
        selectedLink: undefined,
        ancestors: newChild.ancestors,
        childId: id,
        indexInParent,
      }
      const addToParent = over(linkFieldLens, append(newChild.id))
      const storeChild = set(lensPath(['formChildren', newChild.id]), newChild)
      const addHistory = over(
        lensPath(['history']),
        append(newAddedChildHistory),
      )

      return pipe(markAsDirty, addToParent, storeChild, addHistory)(state)

    case ActionTypes.REMOVE_CHILD: {
      const childId = action.payload.childId
      const childToRemove = state.formChildren[childId]
      if (!childToRemove) {
        console.warn('REMOVE_CHILD but child id not found')
        return state
      }

      let parentId = last(childToRemove.ancestors)

      if (!parentId) {
        throw new Error('Child without parent identifier')
      }
      let parentLinkFieldPath: string[]
      if (parentId && parentId === state.rootId) {
        parentLinkFieldPath = ['rootData', childToRemove.parentField]
      } else if (parentId) {
        parentLinkFieldPath = [
          'formChildren',
          parentId,
          'data',
          childToRemove.parentField,
        ]
      } else {
        return state
      }
      const linkFieldLens = lensPath(parentLinkFieldPath)
      const removeFromParent = over(
        linkFieldLens,
        reject<string>((id) => id === childId),
      )

      const getDescendants = pipe(
        (x: { [id: string]: ChildSubmission }) => values(x),
        (children = []) =>
          filter((child) => child.ancestors.includes(childId), children),
        map(prop('id')),
      )
      const descendantIds = getDescendants(state.formChildren)
      const destroyChildAndDescendants = over(
        lensPath(['formChildren']),
        omit(descendantIds.concat(childId)),
      )

      const childFiles = state.files.filter((f) => f.dataPath[1] === childId)
      const removeFiles = over(filesLens, without(childFiles))

      return pipe(
        markAsDirty,
        removeFromParent,
        removeFiles,
        destroyChildAndDescendants,
      )(state)
    }
    case ActionTypes.DUPLICATE_CHILD: {
      const { newId, ancestors, parentField, newChildren } = action.payload

      const addToParent = makeAddToParent(ancestors, parentField, newId)
      const childrenIndexed = indexBy(prop('id'), newChildren)
      const storeChildren = over(
        lensPath(['formChildren']),
        mergeLeft(childrenIndexed),
      )
      return pipe(markAsDirty, addToParent, storeChildren)(state)
    }
    case ActionTypes.GO_TO_CHILD:
      const child = state.formChildren[action.payload.childId]
      if (!child) return state
      const { index = 0, showIndex = false } = action.payload
      const newChildHistory = {
        ...lastHistory,
        modelId: action.payload.modelId,
        selectedLink: undefined,
        ancestors: child.ancestors || [],
        childId: action.payload.childId,
        indexInParent: showIndex && index != null ? index + 1 : null,
      }
      return {
        ...state,
        history: updateHistory(newChildHistory, state.history),
      }

    case ActionTypes.GO_TO_LINK:
      const newLinkHistory = {
        ...lastHistory,
        selectedLink: action.payload.linkName,
      }
      return {
        ...state,
        history: state.history.concat(newLinkHistory),
      }

    case ActionTypes.GO_BACK:
      return {
        ...state,
        history: state.history.slice(0, state.history.length - 1),
      }

    case ActionTypes.GO_TO_ROOT:
      return { ...state, history: [state.history[0]] }

    case ActionTypes.UPDATE_FORM_DATA:
      let { fieldName, value } = action.payload
      // Better to do on Saving?? Otherwise we need
      // to keep track of existing files for removal

      let formPath = makeFieldPath(lastHistory, fieldName)
      const operation = isNil(value)
        ? dissocPath<FormState>(formPath)
        : assocPath<any, FormState>(formPath, value)

      return pipe(markAsDirty, operation)(state)

    case ActionTypes.SUBMISSION_SOURCE_SET: {
      let { fieldName, sourceId, sourceModelId } = action.payload
      const isRoot = isNil(lastHistory.childId)
      if (isRoot) return state
      let sourceIdPath = [
        'formChildren',
        lastHistory.childId as string,
        'sourceId',
      ]
      let sourceModelIdPath = [
        'formChildren',
        lastHistory.childId as string,
        'sourceModelId',
      ]
      let formPath = makeFieldPath(lastHistory, fieldName)
      return pipe(
        assocPath<string, FormState>(sourceIdPath, sourceId),
        assocPath<string, FormState>(sourceModelIdPath, sourceModelId),
        assocPath(formPath, sourceId),
      )(state)
    }

    case ActionTypes.SET_INITIAL_DATA:
      return {
        ...state,
        isDirty: false,
        rootId: action.payload.id,
        sourceId: action.payload.sourceId,
        sourceModelId: action.payload.sourceModelId,
        sourceType: action.payload.sourceType,
        inventoryId: action.payload.inventoryId,
        rootData: action.payload.formData,
        formChildren: action.payload.formChildren,
        files: action.payload.files || [],
        location: action.payload.location,
        type: action.payload.type,
      }

    case ActionTypes.SET_LOCATION:
      if (!state.location) {
        return {
          ...state,
          location: action.payload.location,
          isDirty: isNil(state.location),
        }
      } else {
        const shouldConfirm = !isSameLocation(
          action.payload.location,
          state.location,
        )
        if (shouldConfirm) {
          return {
            ...state,
            confirmLocation: true,
            newLocation: action.payload.location,
          }
        } else return state
      }

    case ActionTypes.SET_GROUP:
      const groupHistoryEntry = {
        ...lastHistory,
        groupName: action.payload.groupName,
      }
      // when navigating between groups we replace history
      // so that GoBack returns to the group layout
      const isInGroup = Boolean(lastHistory.groupName)

      return {
        ...state,
        history: isInGroup
          ? update(state.history.length - 1, groupHistoryEntry, state.history)
          : append(groupHistoryEntry, state.history),
      }

    case ActionTypes.INIT:
      return {
        ...state,
        rootId: action.payload.submissionId,
        history: [
          {
            modelId: action.payload.modelId,
            ancestors: [],
            indexInParent: null,
          },
        ],
        rootModelId: action.payload.modelId,
        rootData: {},
        formChildren: {},
      }

    case ActionTypes.ADD_FILE: {
      const { fieldName, file } = action.payload
      const newFile: SubmissionFile = createSubmissionFile({
        contentType: file.type,
        name: file.name,
        size: file.size,
        file: file,
        dataPath: makeDataPath(lastHistory, fieldName),
      })

      // set up link in fieldName
      const formPath = makeFieldPath(lastHistory, fieldName)
      const storeFileReference = set(lensPath(formPath), newFile.id)
      const addFile = over(filesLens, append(newFile))

      return pipe(markAsDirty, storeFileReference, addFile)(state)
    }
    case ActionTypes.REMOVE_FILE: {
      const { fileId, fieldName } = action.payload
      const formPath = makeFieldPath(lastHistory, fieldName)
      const removeReference = dissocPath<FormState>(formPath)
      const removeFile = over(
        filesLens,
        filter((f: SubmissionFile) => f.id !== fileId),
      )
      return pipe(markAsDirty, removeFile, removeReference)(state)
    }
    case ActionTypes.REPLACE_FILE: {
      const { fileId, file, fieldName } = action.payload
      const formPath = makeFieldPath(lastHistory, fieldName)
      const newFile = createSubmissionFile({
        name: file.name,
        contentType: file.type,
        file: file,
        size: file.size,
        dataPath: makeDataPath(lastHistory, fieldName),
      })

      const removeFile = over(
        filesLens,
        filter((f: SubmissionFile) => f.id !== fileId),
      )
      const addFile = over(filesLens, append(newFile))
      const replaceReference = set(lensPath(formPath), newFile.id)

      return pipe(markAsDirty, removeFile, addFile, replaceReference)(state)
    }

    case ActionTypes.MARK_UNTOUCHED: {
      return markAsUntouched(state)
    }

    case ActionTypes.REPLACE_LOCATION: {
      if (!state.newLocation) return state
      return {
        ...state,
        location: state.newLocation,
        newLocation: undefined,
        confirmLocation: false,
        isDirty: true,
      }
    }

    case ActionTypes.DISCARD_NEW_LOCATION: {
      return {
        ...state,
        newLocation: undefined,
        confirmLocation: false,
      }
    }
    case ActionTypes.SET_ERROR: {
      return pipe(
        markAsDirty,
        assocPath(['lastError'], action.payload.error),
      )(state)
    }
    case ActionTypes.CLEAN_ERROR: {
      return dissocPath(['lastError'], state)
    }
  }
}

const makeFieldPath = (currentHistory: IHistory, fieldName: string) =>
  currentHistory.childId
    ? ['formChildren', currentHistory.childId, 'data', fieldName]
    : ['rootData', fieldName]
const makeDataPath = (currentHistory: IHistory, fieldName: string) =>
  currentHistory.childId
    ? ['children', currentHistory.childId, 'data', fieldName]
    : ['data', fieldName]
