import React from 'react'
import {
  IonToolbar,
  IonText,
  IonIcon,
  IonButtons,
  IonButton,
} from '@ionic/react'
import { logInOutline, alertCircleOutline } from 'ionicons/icons'
import './LoginExpiredMessage.css'
import { useNetwork } from 'context/NetworkContext'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { log } from 'common/devLog'

interface LoginExpiredMessageProps {
  visible?: boolean
}

const LoginExpiredMessage: React.FC<LoginExpiredMessageProps> = ({
  visible = false,
}) => {
  const { isOffline } = useNetwork()
  const { needReLogin, tryRefreshToken } = useUser()
  const t = useTranslation()

  if (!visible || isOffline || !needReLogin()) {
    return null
  }

  return (
    <IonToolbar color="warning" className="login-expired-message">
      <p className="login-expired-message-text">
        <IonIcon className="ion-margin-horizontal" icon={alertCircleOutline} />
        <IonText>{t('network.loginExpired')}</IonText>
      </p>
      <IonButtons slot="end">
        <IonButton
          onClick={() => {
            tryRefreshToken(true).catch((e) => {
              log('LoginExpiredMessage: tryRefreshToken error ', e)
            })
          }}
        >
          <IonIcon slot="icon-only" icon={logInOutline} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  )
}

export default LoginExpiredMessage
