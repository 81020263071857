import { IonAlert } from '@ionic/react'
import useTranslation from 'hooks/useTranslation'
import React, { useEffect, useMemo, useState } from 'react'
import getPWADisplayMode from 'services/getDisplayMode'
import isDev from 'common/isDev'
import { useNetwork } from 'context/NetworkContext'

function InstallAppAlert() {
  const [showAlert, setShowAlert] = useState(false)
  const { isOffline } = useNetwork()
  const t = useTranslation()
  useEffect(() => {
    if (getPWADisplayMode() === 'browser' && !isDev) setShowAlert(true)
  }, [])
  const primaryButton = useMemo(() => {
    return {
      text: t('buttons.ok'),
      handler: () => setShowAlert(!showAlert),
    }
  }, [showAlert, t])

  if (isOffline) return null
  return (
    <IonAlert
      isOpen={showAlert}
      buttons={[primaryButton]}
      header={t('notifications.appNotInstalledHeader')}
      message={t('notifications.appNotInstalledMessage')}
    />
  )
}

export default InstallAppAlert
