import { Translation } from './common'

export enum FieldType {
  Boolean = 'boolean',
  Text = 'text',
  Numeric = 'numeric',
  Select = 'select',
  Image = 'image',
  File = 'file',
  Geopoint = 'geopoint',
  Datetime = 'datetime',
  Link = 'link',
  InspectionAssetReference = 'inspectionAssetReference',
  AssetReference = 'assetReference',
  Note = 'note',
  Sketch = 'sketch',
}

export interface ContentFieldBase {
  _id: string
  name: string
  label: string
  description?: string
  fileId?: string | null
  hint?: string
  relevant?: string
  locales: Translation[]
  meta: {
    readOnly?: boolean
    required?: boolean
    useAsTitle?: boolean
    uncopyable?: boolean
  }
}

export interface BooleanField extends ContentFieldBase {
  type: FieldType.Boolean
  defaultValue?: boolean
  trueLabel?: string
  falseLabel?: string
}

export interface TextField extends ContentFieldBase {
  type: FieldType.Text
  minLength?: number
  maxLength?: number
  multiLine: boolean
}

export interface NumericField extends ContentFieldBase {
  type: FieldType.Numeric
  min?: Number
  max?: number
  numericType: 'integer' | 'decimal'
  /** A text suffix */
  suffix?: string
  /** Step increment */
  increment?: Number
  /** Number of decimals */
  round?: Number
}

export interface SelectField extends ContentFieldBase {
  type: FieldType.Select
  isMultiple: boolean
  listName: string
}

export interface ImageField extends ContentFieldBase {
  type: FieldType.Image | FieldType.File
}

export interface SketchField extends ContentFieldBase {
  type: FieldType.Sketch
  height?: number
  width?: number
  defaultColor?: string
  defaultStrokeWidth?: number
  aspectRatio?:
    | 'none'
    | 'xMidYMid'
    | 'xMidYMax'
    | 'xMaxYMax'
    | 'xMinYMax'
    | 'xMinYMid'
    | 'xMaxYMid'
    | 'xMaxYMin'
    | 'xMidYMin'
    | 'xMinYMin'
  showGrid: boolean
  backgroundColor: string
  exportWithBackground: boolean
  backgroundFileId?: string
  /** Which field on the same model represent its background */
  backgroundFieldName?: string
  allowCustomBackground: boolean
}

export interface GeoLocationField extends ContentFieldBase {
  type: FieldType.Geopoint
  meta: ContentFieldBase['meta'] & {
    ownPosition?: boolean
  }
}

export interface DateTimeField extends ContentFieldBase {
  type: FieldType.Datetime
  includeTime: boolean
  /** JSON encoding of a date, if any */
  minDate?: string
  /** JSON encoding of a date, if any */
  maxDate?: string
}

export interface LinkField extends ContentFieldBase {
  type: FieldType.Link
  /** content model id */
  ofType: string
  /** if the field stores multiple child "forms" or only one  */
  isMultiple: boolean
  /** Min number of linked forms for eventual validation */
  minLength?: number
  /** Max number of linked forms, when there are
   * enough linked forms the user should not be able
   * to add more
   */
  maxLength?: number
}

export interface InspectionAssetReferenceField extends ContentFieldBase {
  type: FieldType.InspectionAssetReference
}

export interface AssetReferenceField extends ContentFieldBase {
  type: FieldType.AssetReference
  path: string
  references: {
    modelId: string
    fieldName: string
  }[]
}

export interface NoteField extends ContentFieldBase {
  type: FieldType.Note
  appearance: 'note' | 'header'
}

export type ContentField =
  | TextField
  | BooleanField
  | NumericField
  | SelectField
  | ImageField
  | GeoLocationField
  | DateTimeField
  | LinkField
  | InspectionAssetReferenceField
  | AssetReferenceField
  | NoteField
  | SketchField
