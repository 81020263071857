import React, { ReactNode, Dispatch, useReducer, useContext } from 'react'
import reducer, { FormState, INITIAL_STATE } from './reducer'
import { FormAction } from './actions'
import {
  ModelSummaryProvider,
  useModelSummaryContext,
} from 'context/ModelSummaryContext'

const StateContext = React.createContext<FormState>(INITIAL_STATE)
const DispatchContext = React.createContext<Dispatch<FormAction>>(() => {})

export function FormContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  /* process.env.NODE_ENV === 'development' &&
    console.log('FormState', state) */
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <ModelSummaryProvider>{children}</ModelSummaryProvider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export const useFormState = () => useContext(StateContext)
export const useDispatchContext = () => useContext(DispatchContext)

export const useModelSummary = useModelSummaryContext

export default FormContextProvider
