import React, { useState, useCallback } from 'react'
import { IonItemGroup, IonItemDivider } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { groupBy, prop } from 'ramda'

import Item from 'ui/components/items/Item'
import Typography, { Variant } from 'ui/components/typography/Typography'
import RemoteInspectionMenu from 'ui/components/menus/RemoteInspectionMenu'

import { useCurrentLocale } from 'context/LocaleContext'
import { useRemoveSubmission } from 'hooks/submission'
import useRemoveInspection from 'hooks/useRemoveInspection'

import { ContentModel, extractTitle, getTitle } from 'core/ContentModel'
import { Inspection } from 'core/Inspections'
import {
  Submission,
  SubmissionType,
  SubmissionSourceType,
} from 'core/Submission'

import formatDate from 'common/formatDate'

interface SubmissionItemListProps {
  assetTitle?: string
  models: ContentModel[]
  submissions?: Submission[]
  inspections?: Inspection[]
}

const SubmissionItemList: React.FC<SubmissionItemListProps> = (props) => {
  const { assetTitle, models, submissions = [], inspections = [] } = props

  const { push } = useHistory()

  const locale = useCurrentLocale()

  const { removeSubmission } = useRemoveSubmission()
  const [removeInspection] = useRemoveInspection()

  const [inspectionMenu, setInspectionMenu] = useState<Inspection>()

  const handleEditSubmission = useCallback(
    (
      assetId: string,
      submissionModelId: string,
      submissionId: string,
      submissionType: SubmissionType,
      sourceType: SubmissionSourceType = SubmissionSourceType.Remote,
    ) => {
      // NOTE this component works for inspections and interventions only
      const type =
        submissionType === SubmissionType.NewInspection
          ? 'inspection'
          : 'intervention'

      const path =
        sourceType === SubmissionSourceType.Remote
          ? `/edit-${type}`
          : `/edit-local-${type}`

      const params = [assetId, submissionModelId, submissionId].join('/')

      push(`${path}/${params}`)
    },
    [push],
  )

  // misc
  const submissionsByModel = groupBy(prop('modelId'), submissions)

  const inspectionsByType = groupBy(prop('inspectionModelId'), inspections)

  return (
    <>
      {models.map((model) =>
        (submissionsByModel[model.id] ?? []).length > 0 ||
        (inspectionsByType[model.id] ?? []).length > 0 ? (
          <IonItemGroup key={model.id}>
            <IonItemDivider color="light">
              <Typography variant={Variant.body2} color="medium">
                {getTitle(locale, model)}
              </Typography>
            </IonItemDivider>
            {submissionsByModel[model.id]?.map((submission) => (
              <Item
                key={submission.id}
                title={
                  extractTitle(model, submission) ??
                  assetTitle ??
                  getTitle(locale, model)
                }
                details={formatDate(submission.lastUpdatedAt)}
                status={submission.status}
                onClick={() =>
                  handleEditSubmission(
                    submission.sourceId!,
                    submission.modelId,
                    submission.id,
                    submission.type,
                    submission.sourceType,
                  )
                }
                onDelete={() => removeSubmission(submission.id)}
              />
            ))}
            {inspectionsByType[model.id]?.map((inspection) => (
              <Item
                key={inspection.id}
                title={
                  extractTitle(model, inspection) ??
                  assetTitle ??
                  getTitle(locale, model)
                }
                details={formatDate(inspection.inspectionDate)}
                status="downloaded"
                onClick={() => setInspectionMenu(inspection)}
                onDelete={() => removeInspection(inspection.id)}
              />
            ))}
          </IonItemGroup>
        ) : null,
      )}
      {inspectionMenu ? (
        <RemoteInspectionMenu
          inspection={inspectionMenu}
          onDidDismiss={() => setInspectionMenu(undefined)}
        />
      ) : null}
    </>
  )
}

export default SubmissionItemList
