import { Files } from './db'
import { API_URL } from './network/constants'

export function saveFile(fileId: string, blob: Blob): Promise<any> {
  return Files.put({ id: fileId, blob }, fileId)
}

export function readFile(fileId: string) {
  return Files.get(fileId)
}

export const prefixRemoteUri = (uri: string): string => {
  if (uri.startsWith(API_URL)) return uri
  const newUri = [API_URL, 'uploads', uri].filter((x) => x != null).join('/')
  /* console.log('new uri', uri, newUri) */
  return newUri
}

export const fileExists = async (fileId: string): Promise<boolean> => {
  const cachedFile = await Files.get(fileId)
  return cachedFile != null
}
