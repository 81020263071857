import React from 'react'
import { IonToolbar, IonText, IonIcon } from '@ionic/react'
import { airplaneOutline, cloudOfflineOutline } from 'ionicons/icons'
import './OfflineMessage.css'
import { useNetwork } from 'context/NetworkContext'
import useTranslation from 'hooks/useTranslation'

interface OfflineMessageProps {
  visible?: boolean
}

const OfflineMessage: React.FC<OfflineMessageProps> = ({ visible = false }) => {
  const { isOffline, isRealOffline } = useNetwork()
  const t = useTranslation()

  if (!visible || !isOffline) {
    return null
  }
  return (
    <IonToolbar color="danger">
      <p className="offline-message-text">
        <IonIcon
          size="large"
          color="light"
          className="ion-margin-horizontal"
          icon={isRealOffline ? cloudOfflineOutline : airplaneOutline}
        />
        <IonText>
          {t(isRealOffline ? 'network.offline' : 'network.manualOffline')}
        </IonText>
      </p>
    </IonToolbar>
  )
}

export default OfflineMessage
