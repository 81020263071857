import { log } from 'common/devLog'
import { useQuery } from 'react-query'

import { fetchDocuments } from 'services/configuration'

const useDocuments = ({ enabled }: { enabled: boolean }) => {
  return useQuery('docs', async () => fetchDocuments(), {
    onError: (error) => {
      log('Error fetching documents', { error })
    },
    enabled,
  })
}

export default useDocuments
