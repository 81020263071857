export const checkPersistentStorage = () => {
  if (navigator.storage && 'persist' in navigator.storage) {
    return navigator.storage.persisted()
  }
  return Promise.resolve(false)
}

export const requestPersistentStorage = async () => {
  try {
    if (navigator.storage && 'persist' in navigator.storage) {
      const isPersisted = await navigator.storage.persist()
      return isPersisted
    }
  } catch (e) {
    console.error('Error requesting persistent storage', e)
  }
  return false
}

export const requestPersistentStorageIfNeeded = async () => {
  const isPersisted = await checkPersistentStorage()
  if (!isPersisted) {
    await requestPersistentStorage()
  }
}
