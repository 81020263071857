import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react'
import {
  IonList,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { mapOutline } from 'ionicons/icons'
import { useLocation, useHistory } from 'react-router-dom'

import AppLayout from 'ui/layouts/AppLayout'
import { EmptyItem } from 'ui/components/loaders/EmptyItem'
import RemoteAssetItem from 'components/RemoteAssetItem'
import LocalAssetItem from 'components/LocalAssetItem'
import RemoteAssetMenu from 'ui/components/menus/RemoteAssetMenu'
import LocalAssetMenu from 'ui/components/menus/LocalAssetMenu'
import Order, { SortOptions } from 'ui/components/order/Order'
import Typography, { Variant } from 'ui/components/typography/Typography'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import { useRemoveAsset } from 'hooks/assets'
import { useRemoveSubmission } from 'hooks/submission'

import { FullAsset, AssetType } from 'core/Assets'
import { Submission } from 'core/Submission'
import { getTitle } from 'core/ContentModel'

import Filter from './Filter'

import useModelFilter from './useModelFilter'
import useAssetsByModel from './useAssetsByModel'
import useOrderAssets from './useOrderAssets'

import './InProgress.css'
import { useNetwork } from 'context/NetworkContext'
import { ModelSummaryProvider } from 'context/ModelSummaryContext'

type MenuState = {
  type: AssetType
  data: FullAsset | Submission
}

type LocationState = { assetId: string }

const goToAsset = (assetId: string): boolean => {
  const node = document.getElementById(assetId)

  if (!node) return false

  // REVIEW could add a little timeout so that the item is fully expanded before
  // scrolling it into view
  node.scrollIntoView({
    block: 'center',
    inline: 'nearest',
    behavior: 'smooth',
  })

  // NOTE trigger a click event only if not already expanded
  if (!node.dataset.expanded) {
    node.click()
  }

  return true
}

const InProgress = () => {
  const location = useLocation<LocationState | undefined>()
  const { replace } = useHistory()

  const locale = useCurrentLocale()
  const t = useTranslation()

  const { isOffline } = useNetwork()

  const { selectedModels } = useModelFilter()

  const { remoteAssetsByModel, localAssetsByModel } = useAssetsByModel()

  // remote assets
  const [removeAsset] = useRemoveAsset()

  // local assets
  const { removeSubmission } = useRemoveSubmission()

  // map
  const handleMapClick = useCallback(() => {
    replace('/take-data/in-progress/map')
  }, [replace])

  // menu (for remote and local assets)
  const [menu, setMenu] = useState<MenuState>()
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    date: { enabled: false, direction: 'asc' },
    name: { enabled: false, direction: 'asc' },
  })

  const { models: orderedModels, assets: orderedAssets } = useOrderAssets(
    selectedModels,
    remoteAssetsByModel as Record<string, FullAsset[]>,
    localAssetsByModel as Record<string, Submission[]>,
    sortOptions,
  )

  const handleSortChange = useCallback((options: SortOptions) => {
    setSortOptions(options)
  }, [])

  const handleDismiss = useCallback(() => {
    setMenu(undefined)
  }, [])

  // navigating back from the map
  useLayoutEffect(() => {
    if (location.state?.assetId) {
      const assetId = location.state.assetId

      // REVIEW for some reason the node will not be found after a page refresh
      // NOTE using `onIonViewEnter` does not work either
      // HACK try focusing the asset once and, if it fails, try again in one sec
      if (!goToAsset(assetId)) {
        setTimeout(() => goToAsset(assetId), 1000)
      }
    }
  }, [location.state])

  const count = useMemo(
    () =>
      selectedModels.reduce((acc, model) => {
        return (
          acc +
          (remoteAssetsByModel[model.id]?.length ?? 0) +
          (localAssetsByModel[model.id]?.length ?? 0)
        )
      }, 0),
    [selectedModels, remoteAssetsByModel, localAssetsByModel],
  )

  return (
    <AppLayout
      pageTitle={t('takeData.inProgress')}
      actions={
        <IonButton
          slot="end"
          fill="clear"
          onClick={handleMapClick}
          disabled={isOffline}
        >
          <IonIcon slot="icon-only" color="primary" icon={mapOutline} />
        </IonButton>
      }
    >
      <div className="takeData-results">
        <Typography variant={Variant.caption}>
          {t('results.count', { count })}
        </Typography>
        <div className="takeData-actions">
          <Order sortOptions={sortOptions} onChange={handleSortChange} />
          <Filter />
        </div>
      </div>
      <ModelSummaryProvider>
        <IonList lines="full" style={{ padding: 0 }}>
          {count === 0 ? (
            <EmptyItem />
          ) : (
            orderedModels.map((model) =>
              orderedAssets[model.id].length > 0 ? (
                <IonItemGroup key={model.id}>
                  <IonItemDivider color="light">
                    <IonLabel>
                      <Typography
                        variant={Variant.body2}
                        style={{ fontWeight: 'bold' }}
                      >
                        {getTitle(locale, model)}
                      </Typography>
                    </IonLabel>
                  </IonItemDivider>

                  {orderedAssets[model.id].map((asset) =>
                    asset.category === AssetType.Local ? (
                      <LocalAssetItem
                        key={asset.id}
                        submission={asset as Submission}
                        onClick={() =>
                          setMenu({
                            type: AssetType.Local,
                            data: asset,
                          })
                        }
                        onRemove={removeSubmission}
                      />
                    ) : asset.category === AssetType.Remote ? (
                      <RemoteAssetItem
                        key={asset.id}
                        asset={asset as FullAsset}
                        onClick={() =>
                          setMenu({
                            type: AssetType.Remote,
                            data: asset,
                          })
                        }
                        onRemove={removeAsset}
                      />
                    ) : null,
                  )}
                </IonItemGroup>
              ) : null,
            )
          )}
        </IonList>
        {menu ? renderMenu(menu, handleDismiss) : null}
      </ModelSummaryProvider>
    </AppLayout>
  )
}

const renderMenu = (menu: MenuState, onDidDismiss: () => void) => {
  switch (menu.type) {
    case AssetType.Remote:
      return (
        <RemoteAssetMenu
          asset={menu.data as FullAsset}
          onDidDismiss={onDidDismiss}
        />
      )
    case AssetType.Local:
      return (
        <LocalAssetMenu
          submission={menu.data as Submission}
          onDidDismiss={onDidDismiss}
        />
      )
    default:
      return null
  }
}

export default InProgress
