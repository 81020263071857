import React, { useState } from 'react'
import {
  airplaneOutline,
  cloudOfflineOutline,
  chevronDownOutline,
  chevronUpOutline,
} from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { useNetwork } from 'context/NetworkContext'
import useTranslation from 'hooks/useTranslation'

interface OfflineStateProps {
  visible: boolean
  message?: string
}

const OfflineState: React.FC<OfflineStateProps> = ({ visible, message }) => {
  const { isOffline, isRealOffline } = useNetwork()
  const [isMessageVisible, setMessageVisible] = useState(false)

  const t = useTranslation()

  if (!visible || !isOffline) {
    return null
  }

  return (
    <div className="image-warning">
      <div
        className="offline-message-header"
        onClick={() => setMessageVisible((p) => !p)}
      >
        <p className="offline-message-text">
          <IonIcon
            className="ion-margin-horizontal"
            color="dark"
            icon={isRealOffline ? cloudOfflineOutline : airplaneOutline}
          />
          {t(isRealOffline ? 'network.offline' : 'network.manualOffline')}
        </p>
        <IonIcon
          className="chevron-icon"
          color="dark"
          icon={isMessageVisible ? chevronUpOutline : chevronDownOutline}
        />
      </div>
      {isMessageVisible && message && (
        <p className="offline-message-detail">{message}</p>
      )}
    </div>
  )
}

export default OfflineState
