import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { isEmpty, assocPath, pipe, assoc } from 'ramda'
import Export, { ExportTab } from 'ui/views/Export'
import { SubmissionStatus } from 'core/Submission'
import { useSubmissionByStatus, useRemoveSubmission } from 'hooks/submission'
import useExport from 'hooks/useExport'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import normalizeString from 'common/normalizeString'
import SendDataPopover from 'ui/views/Export/SendDataPopover'
import useToast from 'hooks/useToast'
import useTranslation from 'hooks/useTranslation'
import { UnathorizedError } from 'services/network/constants'
import { IExportError } from 'useCases/exportSubmissions'
import useUser from 'hooks/useUser'
import generateZip from './generateZip'
import useLocalConfiguration from 'hooks/useLocalConfiguration'
import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'
import { SortOptions } from 'ui/components/order/Order'

import useOrderSubmissions from './useOrderSubmissions'
import { log } from 'common/devLog'

type ExportState = {
  keywordSearch: string
  selectedTab: ExportTab
  isCanceling: boolean
  isExporting: boolean
  isDownloading: boolean
  showDownloadAlert: boolean
  selectedSubmissionIds: string[]
  errors: IExportError[]
}

export default function ExportPage() {
  const [exportState, setExportState] = useState<ExportState>({
    keywordSearch: '',
    selectedTab: 'all',
    isCanceling: false,
    isExporting: false,
    isDownloading: false,
    showDownloadAlert: false,
    selectedSubmissionIds: [],
    errors: [],
  })
  const {
    keywordSearch,
    selectedTab,
    isCanceling,
    isExporting,
    isDownloading,
    showDownloadAlert,
    selectedSubmissionIds,
    errors,
  } = exportState
  const { tryRefreshToken /* , logout */ } = useUser()
  const [progress, setProgress] = useState({
    totalSubmissions: 0,
    currentSubmission: 0,
    totalFiles: 0,
    currentFile: 0,
  })

  const [sortOptions, setSortOptions] = useState<SortOptions>({
    date: { enabled: false, direction: 'asc' },
  })

  const {
    data: finished,
    refetch: refetchFinished,
    isFetching: isFetchingFinished,
  } = useSubmissionByStatus(SubmissionStatus.finished)
  const {
    data: uploaded,
    refetch: refetchUploaded,
    isFetching: isFetchingUploaded,
  } = useSubmissionByStatus(SubmissionStatus.uploaded)
  const {
    data: draft,
    refetch: refetchDraft,
    isFetching: isFetchingDraft,
  } = useSubmissionByStatus(SubmissionStatus.draft)

  const { removeSubmission, isFetching: isRemoving } = useRemoveSubmission()

  const { startExport, cancelExport } = useExport()
  const { success, error } = useToast()
  const t = useTranslation()
  const { config } = useLocalConfiguration()

  const visibleSubmissions = useMemo(() => {
    switch (selectedTab) {
      case 'finished':
        return finished || []
      case 'sent':
        return uploaded || []
      case 'all':
        return [...(finished || []), ...(uploaded || [])]
      default:
        return []
    }
  }, [finished, selectedTab, uploaded])

  const { sortedSubmissions } = useOrderSubmissions(
    visibleSubmissions,
    sortOptions,
  )

  const handleRemove = useCallback(
    (ids: any) => {
      removeSubmission(ids)
    },
    [removeSubmission],
  )

  const handleExport = useCallback(
    async (submissionIds: string[]) => {
      setExportState(
        pipe(
          assocPath<boolean, ExportState>(['isExporting'], true),
          assocPath(['isCanceling'], false),
        ),
      )
      const submissions =
        finished?.filter((submission) =>
          submissionIds.includes(submission.id.toString()),
        ) || []
      const errors = await startExport(setProgress, submissions).catch(
        (err) => {
          console.log('startExport error', err)
          setExportState(pipe(assoc('isExporting', false)))
          return [{ error: err }]
        },
      )
      setExportState(
        pipe(
          assocPath<Boolean, ExportState>(['isExporting'], false),
          assocPath(['errors'], errors),
        ),
      )
      if (!errors || isEmpty(errors)) {
        refetchFinished()
        refetchUploaded()
        refetchDraft()
        success(t('notifications.exportSuccess'))
      }
    },
    [
      finished,
      startExport,
      refetchFinished,
      refetchUploaded,
      refetchDraft,
      success,
      t,
    ],
  )

  const handleDownload = useCallback(async () => {
    setExportState(assocPath<Boolean, ExportState>(['isDownloading'], true))
    try {
      const submissions =
        visibleSubmissions?.filter((submission) =>
          selectedSubmissionIds.includes(submission.id.toString()),
        ) || []
      const content = await generateZip({
        submissions,
        includeImages: config.zipWithImages,
      })
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(content)
      downloadLink.download =
        'inspector-' + new Date().toLocaleDateString() + '.zip'
      downloadLink.click()
    } catch (err) {
      console.error('Error generating zip with submissions: ' + err)
      error(t('notifications.downloadError'))
    }
    setExportState(
      pipe(
        assocPath<Boolean, ExportState>(['isDownloading'], false),
        assocPath<Boolean, ExportState>(['showDownloadAlert'], false),
        assocPath<string[], ExportState>(['selectedSubmissionIds'], []),
      ),
    )
  }, [
    config.zipWithImages,
    error,
    visibleSubmissions,
    selectedSubmissionIds,
    t,
  ])

  const handleShowDownloadAlert = useCallback((submissionIds: string[]) => {
    setExportState(
      pipe(
        assocPath<Boolean, ExportState>(['showDownloadAlert'], true),
        assocPath<string[], ExportState>(
          ['selectedSubmissionIds'],
          submissionIds,
        ),
      ),
    )
  }, [])

  const handleCloseDownloadAlert = useCallback(() => {
    setExportState(
      pipe(
        assocPath<Boolean, ExportState>(['showDownloadAlert'], false),
        assocPath<string[], ExportState>(['selectedSubmissionIds'], []),
      ),
    )
  }, [])

  const handleCancelExport = useCallback(() => {
    setExportState(assocPath(['isCanceling'], true))
    cancelExport()
  }, [cancelExport])

  const handleTabChange = useCallback((selectedTab: string) => {
    setExportState(assocPath(['selectedTab'], selectedTab))
  }, [])

  const handleOnSearch = useCallback((keywordSearch: string) => {
    setExportState(assocPath(['keywordSearch'], keywordSearch))
  }, [])

  // just once
  useEffect(() => {
    tryRefreshToken().catch((e: Error) => {
      log('Export: tryRefreshToken error ', e)
    })
    //debugger
    /*       if (err.name === 'INVALID_TOKEN') {
        // user session is over, go to login
        console.log('Token expired, should login')
        //logout()
      } else {
        console.log('Error refreshing token', err)
      }
    })  */
  }, [/* logout, */ tryRefreshToken])

  useEffect(() => {
    if (errors && errors.length > 0) {
      if (errors.some((e) => e.error instanceof UnathorizedError)) {
        error(t('notifications.authError'))
        //replace('/login')
      } else {
        error(t('notifications.exportError'))
      }
    }
  }, [error, errors, /* replace, */ t])
  return (
    <>
      <Export
        submissions={sortedSubmissions}
        finishedSubmissions={finished || []}
        draftSubmissions={draft || []}
        selectedTab={selectedTab}
        searchText={normalizeString(keywordSearch)}
        onTabChange={handleTabChange}
        isFetching={
          isFetchingFinished ||
          isFetchingUploaded ||
          isFetchingDraft ||
          isRemoving
        }
        sortOptions={sortOptions}
        onRemove={handleRemove}
        onSearch={handleOnSearch}
        onExport={handleExport}
        onDownload={handleShowDownloadAlert}
        onSortChange={setSortOptions}
        isDownloading={isDownloading}
      />
      {isExporting && (
        <SendDataPopover
          totalSubmissions={progress.totalSubmissions}
          currentSubmission={progress.currentSubmission}
          totalFiles={progress.totalFiles}
          currentFile={progress.currentFile}
          onCancel={handleCancelExport}
          disabled={isCanceling}
        />
      )}
      {
        <ConfirmationAlert
          isOpen={showDownloadAlert}
          acceptText={t('buttons.confirmSelection')}
          cancelText={t('buttons.cancelSelection')}
          title={t('confirmation.downloadZip')}
          description={t('confirmation.downloadZipDescription')}
          onAccept={handleDownload}
          onDidDismiss={handleCloseDownloadAlert}
        />
      }
    </>
  )
}
