import { useContext } from 'react'
import {
  useQuery,
  QueryKey,
  QueryConfig,
  TypedQueryFunction,
  TypedQueryFunctionArgs,
  QueryResult,
} from 'react-query'

import UserContext from 'context/UserContext'

import useToast from 'hooks/useToast'
import useTranslation from 'hooks/useTranslation'

import { UnathorizedError } from 'services/network/constants'
import { useNetwork } from 'context/NetworkContext'

// REVIEW `react-query` overloads the signature of the `useQuery` method, should
// we do the same here?
// SEE https://tkdodo.eu/blog/react-query-and-type-script
function useAuthenticatedQuery<
  TResult,
  TError,
  TArgs extends TypedQueryFunctionArgs,
>(
  queryKey: QueryKey,
  queryFn: TypedQueryFunction<TResult, TArgs>,
  queryConfig?: QueryConfig<TResult, TError>,
): QueryResult<TResult, TError> {
  const { clearUser } = useContext(UserContext)
  const { enabled = true, ...restConfig } = queryConfig || {}
  const { isOffline } = useNetwork()
  const Toast = useToast()
  const t = useTranslation()

  return useQuery(queryKey, queryFn, {
    onError: (err) => {
      if (err instanceof UnathorizedError) {
        Toast.error(t('notifications.authError'))
        clearUser()
      }
    },
    enabled: !isOffline && enabled,
    ...restConfig,
  })
}

export default useAuthenticatedQuery
