import React, { useCallback, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'

import AppLayout from 'ui/layouts/AppLayout'
import AssetCardList from 'ui/components/lists/AssetCardList'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslations from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import { useSearchAssets, useAssetsByModelId } from 'hooks/assets'
import { useDebounce } from 'hooks/useDebounce'

import { getPluralTitle, getTitleField } from 'core/ContentModel'

import AdvancedAssetSearch from './AdvancedAssetSearch'

const ImportAsset: React.FC = () => {
  const t = useTranslations()
  const locale = useCurrentLocale()

  // model
  const { params } = useRouteMatch<{ modelId: string }>()
  const { modelId } = params

  const { data: model } = useModel(modelId)
  const titleField = getTitleField(model)
  const modelTitle = model ? getPluralTitle(locale, model) : ''

  // search
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 600)

  const { isFetching: isLoading, data } = useSearchAssets(
    modelId,
    debouncedSearch,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const handleSearch = useCallback((event: CustomEvent) => {
    const keyword = event.detail.value.trim()
    setSearchText(keyword)
  }, [])

  // assets
  const { data: remoteAssets } = useAssetsByModelId(modelId)

  // REVIEW can we refactor this even further so that we do not need to handle
  // downloaded assets here?
  // IDEA pass a flag down to `AssetCardList` to display downloaded assets by
  // default
  const assets = useMemo(() => {
    if (!data) {
      return (
        remoteAssets?.map((asset) => ({
          id: asset.id,
          title: titleField ? asset.data[titleField] : '-',
          modifiedAt: asset.downloadedAt,
        })) ?? []
      )
    }

    return data.map((asset) => ({
      id: asset.id,
      title: asset.title,
      modifiedAt: asset.lastUpdated,
    }))
  }, [data, remoteAssets, titleField])

  return (
    <AppLayout
      pageTitle={
        modelTitle
          ? t('import.type', { label: modelTitle })
          : t('import.assets')
      }
      onSearch={handleSearch}
      isHeaderFixed={false}
      showOffline={true}
      showLoginExpired
    >
      <AdvancedAssetSearch modelId={modelId} />
      <AssetCardList
        modelId={modelId}
        assets={assets}
        isLoading={isLoading}
        hasSearchText={searchText.length > 0}
      />
    </AppLayout>
  )
}

export default ImportAsset
