///<reference path="../../types/index.d.ts" />
import * as expressions from 'angular-expressions'
import * as R from 'ramda'
import { FullAsset } from 'core/Assets'
import { ContentModel, ModelTypes } from 'core/ContentModel'
import { ChildSubmissions } from '../context/FormContext/reducer'

export function create(expressionText = '') {
  return expressions.compile(expressionText)
}

export function fromXLSForm(xlsFormExpression = '') {
  try {
    let angExpression = convertFromXLSForm(xlsFormExpression)
    //console.log('fromXLSForm', xlsFormExpression, '->', angExpression)
    return expressions.compile(angExpression)
  } catch (err) {
    console.warn(
      'Error parsing calculate / relevant expression',
      xlsFormExpression,
      err,
    )
    return create()
  }
}

export function convertFromXLSForm(xlsFormExpression: string = '') {
  let andClauses = xlsFormExpression.split(
    /\s+and\s+(?=(?:(?:[^"]*"){2})*[^"]*$)(?=(?:(?:[^']*'){2})*[^']*$)/i,
  )
  let parsedAndClauses = andClauses.map((clause) => {
    let orClauses = clause.split(
      /\s+or\s+(?=(?:(?:[^"]*"){2})*[^"]*$)(?=(?:(?:[^']*'){2})*[^']*$)/i,
    )
    return orClauses.map((x) => parseXForm(x)).join(' || ')
  })

  return parsedAndClauses.join(' && ')
}

const parseXForm = R.pipe(
  replaceSelected,
  replaceSelfReferences,
  replaceFieldReference,
)

function replaceSelected(x: string) {
  const selectedRegEx = /selected\(\$\{([\w+?[\-/]*\w+?]*)\},\s*'(.*?)'\)/gi
  // replace selected($xxxx, 'yyyy') with out custom filter xxxx | selected('yyyy')
  return x.replace(selectedRegEx, function (substring, ...matches) {
    return `selected($('${matches[0]}'), '${matches[1]}')`
  })
}

function replaceSelfReferences(x = '') {
  return x.replace(/^\./g, 'value')
}

function replaceFieldReference(x = '') {
  const fieldRegEx = /\$\{([\w+?[\-/]*\w+?]*)\}/gi
  return x.replace(fieldRegEx, "$('$1')")
}

const assetScope =
  (subAssets: FullAsset['subAssets'], model: ContentModel, scope: Object) =>
  (fieldName: string) =>
    getFieldValue(fieldName, getAsset(model, getAssetById, subAssets, scope))

const getAsset = (
  model: ContentModel,
  getAssetById: (assetId: string, subAssets: FullAsset['subAssets']) => Object,
  subAssets: FullAsset['subAssets'],
  scope: Object,
) => {
  switch (model?.modelType) {
    case ModelTypes.asset: {
      return model
    }
    // REVIEW should interventions behave as inspections here?
    case ModelTypes.intervention:
    case ModelTypes.inspection: {
      const currentAssetId =
        Object.keys(subAssets).find((s) => Object.values(scope).includes(s)) ||
        ''
      return getAssetById(currentAssetId, subAssets)
    }
  }
}
const assetRoot = (fullAsset: Object) => (fieldName: string) => {
  getFieldValue(fieldName, fullAsset)
  return getFieldValue(fieldName, fullAsset)
}
const isRootAssetName = (model?: Object) => (modelName: string) =>
  getIsRootAssetName(modelName, model)

const getAssetById = (assetId: string, subAssets: FullAsset['subAssets']) => {
  return subAssets[assetId]
}

function getFieldValue(fieldName: string, submission: Object): any {
  return R.path(['data', fieldName], submission)
}
function getRootAssetName(model?: Object) {
  return R.path(['name'], model)
}
function getIsRootAssetName(modelName: string, model?: Object) {
  return getRootAssetName(model) === modelName
}

type ScopeData = {
  [fieldName: string]: any
}

const commonScope = {
  selected: function (
    data: string[] | string | number | object,
    target: string,
  ) {
    //eslint-disable-next-line
    return Array.isArray(data)
      ? data.includes(target)
      : // eslint-disable-next-line eqeqeq
        data == target
  },
  $: function (this: ScopeData, fieldName: string): any {
    if (typeof fieldName !== 'string') {
      throw new Error(
        `$(field) called with wrong argument. Expected string, got ${typeof fieldName} ${fieldName}`,
      )
    }
    return R.path((fieldName ?? '').split('.'), this)
  },
  getDay: () => new Date().getDate(),
  getMonth: () => new Date().getMonth() + 1,
  getYear: () => new Date().getFullYear(),
  findIndex: function (
    this: ScopeData,
    data: string | object,
    fieldName: string,
    target: string,
  ): number | null {
    let item = typeof data === 'string' ? this[data] : data
    if (!Array.isArray(item[fieldName])) {
      return null
    }
    return (item[fieldName] as string[]).findIndex(
      (record) => record === target,
    )
  },
  switch: function (data: string, conditions = {}) {
    const defaultValue = R.pathOr('', ['default'], conditions)
    return R.pathOr(defaultValue, [data], conditions)
  },
  substr: function (data: string, start: number, length: number) {
    return String(data).substr(start, length)
  },
  upper: function (data = '') {
    return String(data).toUpperCase()
  },
  lower: function (data = '') {
    return String(data).toLowerCase()
  },
  toInt: function (data = '', base = 10) {
    return parseInt(data, base)
  },
  toFloat: function (data: string) {
    return parseFloat(data)
  },
  toNumber: function (data: string) {
    return Number(data)
  },
  max: function (...values: any[]) {
    // flatten so this can be called with an array
    const numbers = R.flatten(values).filter((x) => R.is(Number, x))
    const res = Math.max.apply(Math, numbers)
    return isFinite(res) ? res : null
  },
  min: function (...values: any[]) {
    // flatten so this can be called with an array
    const numbers = R.flatten(values).filter((x) => R.is(Number, x))
    const res = Math.min.apply(Math, numbers)
    return isFinite(res) ? res : null
  },
  log: function (...values: any) {
    // just for debugging!
    console.log.apply(console, values)
  },
  some: function (data: any, target: any) {
    if (!Array.isArray(data)) return false
    return data.some((val) => val === target)
  },
  first: function (data: any) {
    if (data == null) return null
    return R.head(data)
  },
  last: function (data: any) {
    if (data == null) return null
    return R.last(data)
  },
  take: function (num = 1, data: any) {
    if (data == null) return []
    return R.take(num, data)
  },
  takeLast: function (num = 1, data: any) {
    if (data == null) return []
    return R.takeLast(num, data)
  },
  reverse: (data: any) => {
    if (!data || !Array.isArray(data)) return data
    return R.reverse(data)
  },
  filter: (fieldName = '', fieldValue = '', list: any) => {
    if (!list || !Array.isArray(list)) return []
    return list.filter((item) => item[fieldName.trim()] === fieldValue.trim())
  },
  sortAsc: function (data: any, target: string) {
    if (data == null) return data
    const filter = target ? R.prop(target) : R.identity
    return R.sort(R.ascend(filter as any), data)
  },
  sortDesc: function (data: any, target?: string | null) {
    if (data == null) return data
    const filter = target ? R.prop(target) : R.identity
    return R.sort(R.descend(filter as any), data)
  },
  sum: function (data: any) {
    if (!data || !Array.isArray(data)) return null
    return R.sum(data)
  },
  mul: function (data: any) {
    if (!data || !Array.isArray(data)) return null
    return R.reduce(R.multiply, 1, data)
  },
}

export function makeScope(
  scope = {},
  rootData = {},
  ancestors: string[],
  formChildren: ChildSubmissions,
  fullAsset?: FullAsset,
  rootModel?: ContentModel,
  assetRootModel?: ContentModel,
  model?: any,
) {
  const getParentNId = (level: any) => {
    if (level == null) return
    let n = -parseInt(level)
    return R.nth(n, ancestors)
  }
  const getFieldValueOfParentN = (fieldName: string, level: any) => {
    const parentId = getParentNId(level)
    const parentIsRoot = parentId ? parentId === R.nth(0, ancestors) : false

    if (parentIsRoot) return R.path([fieldName], rootData)

    let parentSubmision = parentId ? formChildren[parentId] : null
    return R.path(['data', fieldName], parentSubmision)
  }
  return makeCommonScope({
    ...scope,
    $assetRoot: assetRoot(fullAsset || { data: rootData }),
    $asset: assetScope(fullAsset?.subAssets || {}, model || {}, scope),
    $root: assetRoot({ data: rootData }),
    $rootModelName: function () {
      return getRootAssetName(assetRootModel || rootModel)
    },
    $parent: (fieldName: string) => getFieldValueOfParentN(fieldName, 1),
    $parentN: getFieldValueOfParentN,
    $isRootModel: isRootAssetName(assetRootModel || rootModel),
    //TODO: add assetScope expressions?
  })
}

function makeCommonScope(scope = {}) {
  return Object.assign(scope, commonScope)
}
