import React, { useCallback, useState } from 'react'
import { IonDatetime } from '@ionic/react'
import FormField from '../forms/FormField'
import { FieldProps } from './common'
import useTranslation from 'hooks/useTranslation'
import { parseDate } from 'common/formatDate'

export interface DateTimeProps extends FieldProps {
  min?: string | Date
  max?: string | Date
  displayFormat?: string
  value?: string
}

const DateTime: React.FC<DateTimeProps> = ({
  readOnly,
  label,
  name,
  hint,
  min,
  max,
  displayFormat,
  errorText,
  hasError,
  isRequired,
  onChange,
  value,
  tabIndex,
  fileId,
}) => {
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onConfirm = useCallback(
    (e: any) => {
      e.preventDefault()
      setIsOpen(false)
      onChange(name, e.detail!.value)
    },
    [name, onChange],
  )

  const onOpen = useCallback((e: any) => {
    e.preventDefault()
    setIsOpen(true)
  }, [])
  return (
    <FormField
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      tabIndex={tabIndex}
      fileId={fileId}
      readOnly={readOnly}
    >
      <div className="ion-text-wrap" onClick={onOpen}>
        {value ? parseDate(value, displayFormat) : displayFormat}
      </div>

      {isOpen && (
        <IonDatetime
          readonly={readOnly}
          //min={is(Date, min) ? (min as Date).toJSON() : min as String}
          //max={max}
          //displayFormat={displayFormat}
          presentation="date"
          /*           preferWheel={true} */
          value={value}
          name={name}
          //placeholder={displayFormat}
          cancelText={t('buttons.cancel')}
          doneText={t('buttons.confirmSelection')}
          clearText={t('buttons.removeSelection')}
          onIonChange={onConfirm}
          onIonCancel={onCancel}
          showClearButton={true}
          showDefaultButtons={true}
          firstDayOfWeek={1}
          showDefaultTimeLabel={false}
          size="cover"
        ></IonDatetime>
      )}
    </FormField>
  )
}

export default React.memo(DateTime)
