import { useDispatchContext, useModelSummary } from 'context/FormContext'
import { goToLink, goToRoot, goToChild } from 'context/FormContext/actions'
import { getCurrentHistory } from 'context/FormContext/selectors'
import { makeLinkPath } from 'context/FormContext/treeNavigation'
import { useCurrentLocale } from 'context/LocaleContext'
import { getLocalizedProperty } from 'core/common'
import { LinkField } from 'core/ContentField'
import { getFieldByName } from 'core/ContentModel'
import { last, values, groupBy, prop } from 'ramda'
import { useCallback, useMemo } from 'react'
import { TreeItem } from 'ui/components/menus/TreeNavigation'
import { TreeItemTypes } from 'ui/components/menus/TreeNavigationItem'
import useForm from './useForm'

export function useSubmissionTreeNavigation() {
  const { state, model, rootModel } = useForm()
  const dispatch = useDispatchContext()
  const locale = useCurrentLocale()
  const currentHistory = getCurrentHistory(state)
  const { getModelSummary, hasModelSummary } = useModelSummary()

  const navigateToLink = useCallback(
    (linkName: string) => {
      dispatch(goToLink(linkName))
    },
    [dispatch],
  )
  const navigateToRoot = useCallback(() => {
    dispatch(goToRoot())
  }, [dispatch])

  const navigateToChild = useCallback(
    (
      modelId: string,
      childId: string,
      linkName?: string | null,
      showIndex = false as boolean,
      index?: number | null,
    ) => {
      childId === state.rootId
        ? dispatch(goToRoot())
        : dispatch(goToChild(modelId, childId, showIndex, index))
      if (linkName) {
        dispatch(goToLink(linkName))
      }
    },
    [dispatch, state.rootId],
  )

  const treeOptions = useMemo<TreeItem[]>(() => {
    if (!model) return []
    const selectedId = currentHistory?.childId || (state.rootId as string)
    const directChildren = values(state.formChildren).filter(
      (child) => last(child.ancestors) === selectedId,
    )
    const groupedByLinkField = groupBy(prop('parentField'), directChildren)

    const options: TreeItem[] = Object.keys(groupedByLinkField).reduce(
      (acc: TreeItem[], linkName) => {
        const linkField = getFieldByName(linkName, model) as LinkField
        if (linkField) {
          const { name, isMultiple } = linkField
          const totalOfType = groupedByLinkField[linkName]?.length || 0

          acc.push({
            name: name,
            label: getLocalizedProperty(locale, 'label', linkField),
            childrenNumber: `${isMultiple ? totalOfType : ''}`,
            type: TreeItemTypes.childLink,
            onClick: () => {
              navigateToLink(linkName)
            },
          })
        }
        return acc
      },
      [],
    )
    if (hasModelSummary) {
      const parentOptions = makeLinkPath(
        selectedId,
        state.rootId as string,
        state.rootModelId as string,
        state.rootData,
        state.formChildren,
        navigateToChild,
        getModelSummary,
        locale,
      )
      options.unshift(...parentOptions)
    }
    if (
      (currentHistory?.modelId !== state.rootModelId && options.length) ||
      (currentHistory?.modelId === state.rootModelId &&
        currentHistory.selectedLink)
    ) {
      options.unshift({
        name: rootModel?.name || '',
        label: rootModel
          ? getLocalizedProperty(locale, 'label', rootModel)
          : '',
        type: TreeItemTypes.root,
        onClick: navigateToRoot,
      })
    }

    return options
  }, [
    model,
    currentHistory,
    state.rootId,
    state.formChildren,
    state.rootModelId,
    state.rootData,
    locale,
    navigateToLink,
    rootModel,
    navigateToRoot,
    navigateToChild,
    getModelSummary,
    hasModelSummary,
  ])

  return treeOptions
}
