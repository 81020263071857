import { useContext, createContext, useCallback, useMemo } from 'react'
import { ModelSummary } from 'core/ModelSummary'
import { propOr } from 'ramda'
import useModelSummaries from 'hooks/useModelSummaries'

export type ModelSummaryContextDef = {
  getModelSummary: (modelId: string) => ModelSummary
  hasModelSummary: boolean
}

const ModelSummaryContext = createContext<ModelSummaryContextDef>({
  getModelSummary: () => ({}) as ModelSummary,
  hasModelSummary: false,
})

export const useModelSummaryContext = () => useContext(ModelSummaryContext)

export default ModelSummaryContext

export function ModelSummaryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { data: modelSummaries } = useModelSummaries()
  const getModelSummary = useCallback(
    (modelId: string): ModelSummary => {
      return propOr({}, modelId, modelSummaries)
    },
    [modelSummaries],
  )
  const hasModelSummary = useMemo(
    () => Boolean(modelSummaries && Object.keys(modelSummaries).length),
    [modelSummaries],
  )

  return (
    <ModelSummaryContext.Provider
      value={{
        getModelSummary,
        hasModelSummary,
      }}
    >
      {children}
    </ModelSummaryContext.Provider>
  )
}
