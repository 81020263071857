import { append, lensProp, over, concat, pipe, assoc } from 'ramda'
import { Translation } from './common'
import { ContentField, FieldType } from './ContentField'
import { ContentModel, ModelTypes } from './ContentModel'

export interface ModelSummary {
  id: string
  name: string
  label: string
  pluralLabel: string | undefined
  modelType: ModelTypes
  linkFields: string[]
  fileFields: string[]
  titleField: string
  nonDuplicateFields: string[]
  locales: Translation[]
}

const localesL = lensProp<ModelSummary, 'locales'>('locales')
const addToLocales = (l: Translation[]) => over(localesL, concat(l))

const extractFieldLocales = (f: ContentField) => {
  return f.locales.map((l) => {
    return {
      ...l,
      key: f.name,
    }
  })
}

const addToLinkFields = (fieldName: string) =>
  over(lensProp<ModelSummary, 'linkFields'>('linkFields'), append(fieldName))

const addToFileFields = (fieldName: string) =>
  over(lensProp<ModelSummary, 'fileFields'>('fileFields'), append(fieldName))

const addNonDuplicateFields = (fieldName: string) =>
  over(
    lensProp<ModelSummary, 'nonDuplicateFields'>('nonDuplicateFields'),
    append(fieldName),
  )

export function makeModelSummary(attributes: ContentModel): ModelSummary {
  const { id, name, modelType, fields } = attributes
  const summary = fields.reduce(
    (
      acc: {
        linkFields: string[]
        fileFields: string[]
        titleField: string
        nonDuplicateFields: string[]
        locales: Translation[]
      },
      f,
    ) => {
      if (f.meta.uncopyable)
        acc = addNonDuplicateFields(f.name)(acc as ModelSummary)
      if (f.type === FieldType.Link)
        return pipe(
          assoc(f.name, f.label),
          addToLinkFields(f.name),
          addToLocales(extractFieldLocales(f)),
        )(acc)
      if (
        f.type === FieldType.Image ||
        f.type === FieldType.File ||
        f.type === FieldType.Sketch
      )
        return addToFileFields(f.name)(acc as ModelSummary)
      if (f.meta.useAsTitle) acc.titleField = f.name
      return acc
    },
    {
      linkFields: [],
      fileFields: [],
      nonDuplicateFields: [],
      titleField: '',
      locales: attributes.locales || [],
    },
  )

  return {
    id,
    name,
    label: attributes.label,
    pluralLabel: attributes.pluralLabel,
    modelType,
    ...summary,
  }
}
