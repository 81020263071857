import React, { useCallback } from 'react'
import {
  isPlatform,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonSearchbar,
  IonButton,
  IonIcon,
  IonLabel,
  IonText,
} from '@ionic/react'
import { useHistory, useLocation } from 'react-router'
import { arrowBack } from 'ionicons/icons'
import cx from 'classnames'

import useTranslation from 'hooks/useTranslation'

import OfflineMessage from './OfflineMessage'

import './AppHeader.css'
import LoginExpiredMessage from './LoginExpiredMessage'

export interface AppHeaderProps {
  title: string
  subtitle?: string
  showOffline?: boolean
  showLoginExpired?: boolean
  actions?: React.ReactNode
  hideBackLink?: boolean
  onSearchbarChange?: (e: CustomEvent) => void
  onBackClick?: (e: React.MouseEvent<any, any>) => void
  className?: string
  isHeaderFixed?: boolean
  isSearchbarSticky?: boolean
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const {
    title,
    subtitle,
    actions,
    hideBackLink,
    onSearchbarChange,
    onBackClick,
    className,
    isHeaderFixed = true,
    isSearchbarSticky = true,
    showOffline = false,
    showLoginExpired = false,
  } = props

  const { goBack, replace } = useHistory()
  const location = useLocation()
  const t = useTranslation()

  const handleBackClick = useCallback(
    (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      if (typeof onBackClick === 'function') {
        return onBackClick(e)
      }
      // if there IS history, go back
      if (location.pathname.split('/').length > 2) {
        return goBack()
      }
      // otherwise go to home, maybe a refresh on some inner route happened
      replace('/')
    },
    [onBackClick, goBack, replace, location.pathname],
  )

  // Corrected the definition of headerClassName
  const headerClassName = cx(
    'app-header',
    {
      'app-header-fixed': isHeaderFixed,
      'app-header-scrollable': !isHeaderFixed,
    },
    className,
  )

  return (
    <>
      <IonHeader className={headerClassName}>
        <OfflineMessage visible={showOffline} />
        <LoginExpiredMessage visible={showLoginExpired} />
        <IonToolbar
          className={cx({
            'app-header-toolbar-ios': isPlatform('ios'),
          })}
        >
          <IonButtons slot="start">
            {!hideBackLink ? (
              <IonButton
                slot="start"
                fill="clear"
                className="ion-margin-start"
                onClick={handleBackClick}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            ) : null}
          </IonButtons>

          <IonTitle
            className={cx('app-header-title', {
              'app-header-title-ios': isPlatform('ios'),
              'app-header-title-without-link': hideBackLink,
            })}
          >
            {title}
            {subtitle ? (
              <IonLabel
                className={cx('app-header-subtitle', {
                  'app-header-subtitle-ios': isPlatform('ios'),
                })}
              >
                <IonText className={'app-header-subtitle-text'}>
                  {subtitle}
                </IonText>
              </IonLabel>
            ) : null}
          </IonTitle>
          {actions}
        </IonToolbar>
      </IonHeader>
      {onSearchbarChange ? (
        <IonToolbar
          className={cx('app-searchBar', {
            'app-searchBar-sticky': isSearchbarSticky,
            'app-searchBar-scrollable': !isSearchbarSticky,
          })}
        >
          {/* {isSearchbarSticky && (
            <IonButtons slot="start" className="app-searchBar-backButton">
              <IonButton
                fill="clear"
                className="ion-margin-start"
                onClick={handleBackClick}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </IonButtons>
          )} */}
          <IonSearchbar
            placeholder={t('search.placeholder')}
            onIonChange={onSearchbarChange}
          />
        </IonToolbar>
      ) : null}
    </>
  )
}

export default AppHeader
