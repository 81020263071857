import { useCallback, useEffect } from 'react'
import { useI18n } from '../context/LocaleContext'
import useLocalConfiguration from '../hooks/useLocalConfiguration'
import useUser from '../hooks/useUser'
import useConfig from '../hooks/useConfig'
import Settings from 'ui/views/Settings'
import { log } from 'common/devLog'

const makeLocaleOption = (locale: string) => {
  let option = {
    id: locale,
    value: locale,
    label: '',
  }
  switch (locale) {
    case 'en-GB':
      option.label = 'English'
      break
    case 'fr-FR':
      option.label = 'Français'
      break
    case 'cat':
    case 'ca':
    case 'ca-ES':
      option.label = 'Català'
      break
    case 'ca-VALENCIA':
      option.label = 'Valencià'
      break
    case 'pt-PT':
      option.label = 'Português'
      break
    case 'pt-BR':
      option.label = 'Português (Brasil)'
      break
    case 'es-AR':
      option.label = 'Español (Argentina)'
      break
    case 'es-CL':
      option.label = 'Español (Chile)'
      break
    case 'es-MX':
      option.label = 'Español (México)'
      break
    case 'gl-ES':
      option.label = 'Galego'
      break
    case 'it-IT':
      option.label = 'Italiano'
      break
    case 'es-ES':
    default:
      option.label = 'Español (España)'
  }
  return option
}

const SettingsPage = () => {
  const { locale, options, changeLocale } = useI18n()
  const {
    config,
    setGpsTimeout,
    setLocale,
    setRefresh,
    setAutoDownloadImages,
    setZipWithImages,
  } = useLocalConfiguration()
  const { logout, username, needReLogin, tryRefreshToken } = useUser()
  const { isFetchingConfig, fetchAppConfig } = useConfig()

  const handleLocaleChange = useCallback(
    (locale: string) => {
      setLocale(locale)
      changeLocale(locale)
    },
    [changeLocale, setLocale],
  )
  const handleRefresh = useCallback(() => {
    fetchAppConfig()
      .then(() => setRefresh(Date.now()))
      .catch((err) => {
        console.log('error refreshing', err)
      })
  }, [fetchAppConfig, setRefresh])

  // just once
  useEffect(() => {
    tryRefreshToken().catch((e: Error) => {
      log('Settings: tryRefreshToken error ', e)
    })
  }, [tryRefreshToken])

  return (
    <Settings
      username={username}
      languageOptions={options.map((o) => makeLocaleOption(o.value))}
      selectedLanguage={locale}
      selectedLanguageLabel={makeLocaleOption(locale).label}
      lastConfiguration={config.lastRefresh}
      gpsTimeout={config.gpsTimeout}
      autoDownloadImages={config.autoDownloadImages}
      zipWithImages={config.zipWithImages}
      isFetchingConfig={isFetchingConfig}
      onFetchConfig={handleRefresh}
      onLogout={logout}
      onSelectLanguage={handleLocaleChange}
      onAcceptTimeout={setGpsTimeout}
      onToggleAutoDownloadImages={setAutoDownloadImages}
      onToggleZipWithImages={setZipWithImages}
      needReLogin={needReLogin()}
    />
  )
}

export default SettingsPage
